import {PropsWithoutRef, ReactElement, useContext} from 'react'
import { useForm } from 'react-hook-form'

import MyInputText from '../../../../../../Theme/Components/Input/MyInputText.tsx'
import { Button } from 'primereact/button'
import { CheckListTemplateItemDTO } from '../../../Domain/CheckListTemplateItemDTO.tsx'
import {Uuid} from "../../../../../Shared/Domain/ValueObject/Uuid.ts";
import {AppContext} from "../../../../../../AppContext.tsx";
import {Icon} from "../../../../../../Theme/Components/Icon/Icon.tsx";

export default function TemplateItemForm({
  onAdd,
}: PropsWithoutRef<{
  onAdd: (item: CheckListTemplateItemDTO) => void
}>): ReactElement {
  const resetValues = () => {
    return { guid: '', text: '' }
  }
  const {trans} = useContext(AppContext)
  const { control,handleSubmit, reset } = useForm<{guid:string, text:string}>({
    defaultValues: resetValues(),
  })
  const onAddClickHandler = async () => {
    const handler = handleSubmit((data)=> {
      console.debug('items values', data)
      const newValues = { ...data, guid: Uuid.random(), sort: 0, updatedAt: new Date() }
      reset(resetValues())
      onAdd(newValues)
    })
    await handler()

  }
  return (
    <div className="flex gap-1 sm:gap-3">
      <div className="flex flex-grow-1">
        <MyInputText className="flex-grow-1" autoFocus={true} name={'text'} control={control} rules={{'required':true}} onEnter={() => {
          void onAddClickHandler()
        }} />
      </div>
      <Button type={'button'} severity={'success'} onClick={onAddClickHandler}>
        <span className="hidden sm:flex">{trans.t('Add')}</span>
        <Icon classname="flex sm:hidden" name={'add'} size={24}/>
      </Button>
    </div>
  )
}
