import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {CheckList} from '../../Domain/CheckList.tsx'
import {useCheckListApplication} from "../../Application/useCheckListApplication.ts";
import {CheckListTaskPrimitives} from "../../Domain/CheckListTaskPrimitives.tsx";
import {AccountInfo} from "../../../../../App.tsx";
import {HttpApiRepository} from "../../../../Shared/Domain/Persistence/HttpApiRepository.ts";

type onSuccessFn = (checkList: CheckList) => void

export function useCheckListQueries({guid: checkListSelectedGuid, onSuccessPut, onSuccessDelete, api}: {
    guid?: string;
    onSuccessPut?: onSuccessFn,
    onSuccessDelete?: onSuccessFn
    account: AccountInfo,
    api: HttpApiRepository
}) {

    const queryClient = useQueryClient()
    const {
        findByIdCheckList: findById,
        putCheckList: put,
        findAllCheckList: findAll,
        removeCheckList: remove,
        clear
    } = useCheckListApplication()

    const putCheckList = useMutation({
        mutationFn: async (item: CheckList) => {
            return put(item)
        },
        onMutate: async (putTemplate: CheckList) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            console.debug('onMutate CheckList', putTemplate)
            await queryClient.cancelQueries({queryKey: ['CheckList']})

            // Snapshot the previous value
            const previousCheckList = queryClient.getQueryData(['CheckList'])
            console.debug('onMutate previousCheckList', previousCheckList)

            // Optimistically update to the new value
            queryClient.setQueryData(['CheckList'], (oldTemplates: CheckList[]) => {
                if (!oldTemplates) return []
                if (!oldTemplates.some((t) => t.guid.equals(putTemplate.guid))) {
                    oldTemplates.push(putTemplate)
                } else {
                    for (const item of oldTemplates) {
                        if (!item.guid.equals(putTemplate.guid)) continue
                        Object.assign(item, putTemplate)
                    }
                }
                return [...oldTemplates]
            })

            queryClient.setQueryData(['CheckList', putTemplate.guid.value], putTemplate)

            return {previousTodos: previousCheckList}
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (_err, _newTodo, context) => {
            console.debug('onError ListCheckList', context?.previousTodos)
            queryClient.setQueryData(['CheckList'], context?.previousTodos)
        },
        // Always refetch after error or success:
        onSettled: (_data, error, checkList) => {
            //void queryClient.invalidateQueries({queryKey: ['CheckList']})
            console.debug('onSettled ListCheckList', checkList, error)
            if (!error) {
                console.debug('mutation call onSuccess')
                onSuccessPut?.call(null, checkList)
            }
        },
    })
    const deleteCheckList = useMutation({
        mutationFn: async (item: CheckList) => {
            return remove(item)
        },
        onMutate: async (removedCheckList: CheckList) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            console.debug('onMutate CheckList', removedCheckList)
            await queryClient.cancelQueries({queryKey: ['CheckList']})

            // Snapshot the previous value
            const previous = queryClient.getQueryData(['CheckList'])
            console.debug('onMutate previousCheckList', previous)

            // Optimistically update to the new value
            queryClient.setQueryData(['CheckList'], (oldTemplates: CheckList[]) => {
                if (!oldTemplates) return []
                const resultList = oldTemplates.filter((f) => !f.guid.equals(removedCheckList.guid))
                return [...resultList]
            })
            return {previous}
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (_err, _newTodo, context) => {
            console.debug('onError ListCheckList', context?.previous)
            queryClient.setQueryData(['CheckList'], context?.previous)
        },
        // Always refetch after error or success:
        onSettled: (_data, error, checkList) => {
            //void queryClient.invalidateQueries({queryKey: ['CheckList']})
            //void queryClient.invalidateQueries({queryKey: ['CheckList', checkList.guid]})
            console.debug('onSettled ListCheckList', checkList, error)
            if (!error) {
                console.debug('mutation call onSuccess')
                onSuccessDelete?.call(null, checkList)
            }
        },
    })
    const getChecklist = useQuery({
        queryKey: ['CheckList', checkListSelectedGuid],
        queryFn: () => findById(checkListSelectedGuid!),
        enabled: !!checkListSelectedGuid,
    })

    const getListChecklist = useQuery({
        queryKey: ['CheckList'],
        queryFn: async () => {
            return new Promise((resolve)=> {
                console.debug('[CheckListQueries] getListCheckList Fn')
                api.get({route: 'checkList/all/me'}).then(async (res) => {
                    console.debug('[CheckListQueries] Get checklist/all/me', res)
                    const checklists:CheckList[] = res.data.payload.map(c => {
                        return CheckList.fromPrimitives({
                            archivedAt: c.archivedAt ? new Date(c.archivedAt) : undefined,
                            completedAt: c.completedAt ? new Date(c.completedAt) : undefined,
                            createdAt: new Date(c.createdAt),
                            createdBy: c.createdBy,
                            dueAt: c.dueAt ? new Date(c.dueAt) : undefined,
                            guid: c.guid,
                            name: c.name,
                            tasks: c.tasks.map((t: CheckListTaskPrimitives) => {
                                return {
                                    guid: t.guid,
                                    itemGuid: t.itemGuid,
                                    performedAt: t.performedAt ? new Date(t.performedAt) : undefined,
                                    sort: t.sort,
                                    text: t.text
                                } as CheckListTaskPrimitives
                            }),
                            templateGuid: c.templateGuid,
                            updatedAt: new Date(c.updatedAt),
                        })
                    })
                    // TODO: Esto de borrar todo va dar problemas cuando implemente la paginación. Quiza borrar uno por uno de los realmente recividos
                    await clear()
                    for (const checklist of checklists) {
                        await put(checklist, true)
                        queryClient.setQueryData(['CheckList', checklist.guid.value], checklist)
                    }
                    queryClient.setQueryData(['CheckList'], checklists)
                }).catch(async (ee)=> {
                    if (ee.message.toUpperCase().includes('Forbidden'.toUpperCase())){
                        await clear()
                        //queryClient.setQueryData(['CheckList'], [])
                        await queryClient.invalidateQueries({queryKey:['CheckList'], exact:false, refetchType:'none'})
                    }
                    console.debug('[CheckListQueries] Get checklist error', ee.message)
                })

                resolve(findAll())
            })

        },
    })

    return {
        putCheckList,
        getChecklist,
        getListChecklist,
        deleteCheckList
    }
}
