import { ReactElement } from 'react'
import ListCheckLists from './Components/List/ListCheckLists.tsx'
import NewCheckListTemplateButton from './Components/List/NewCheckListTemplateButton.tsx'
import PageDoubleLayout from '../../../../Theme/Components/Page/PageDoubleLayout.tsx'

export default function CheckListTemplatePage(): ReactElement {
  return (
    <PageDoubleLayout>
      <div className='flex justify-content-center'>
        <NewCheckListTemplateButton />
      </div>
      <ListCheckLists />
    </PageDoubleLayout>
  )
}
