import {CheckListTemplate} from "../Domain/CheckListTemplate.tsx";
import {sqliteConnection} from "../../../Shared/Infrastructure/Persistence/Sqlite/SqliteConnection.ts";
import {Capacitor} from "@capacitor/core";
import {CheckListTemplateEntity} from "../Infrastructure/persistence/TypeOrm/CheckListTemplate.Entity.ts";
import {useContext} from "react";
import {AppContext} from "../../../../AppContext.tsx";

export function useCheckListTemplateApplications() {
    const {eventBus, db}= useContext(AppContext)
    const database = db.options.database
    const checkListTemplateRepository = db.getRepository(CheckListTemplateEntity)
    //const queryClient = useQueryClient()

    const storeData = async () => {
        if (Capacitor.getPlatform() === 'web' && typeof database === 'string') {
            await sqliteConnection.saveToStore(database);
        }
    }
    const putChecklistTemplate = async (template: CheckListTemplate): Promise<void> => {
        try {
            const checkListTemplate = new CheckListTemplateEntity()
            const primitives = template.toPrimitives()
            console.debug('preMapping:', checkListTemplate, primitives)
            checkListTemplate.name = primitives.name
            checkListTemplate.items = primitives.items
            checkListTemplate.guid = primitives.guid
            checkListTemplate.global = primitives.global
            checkListTemplate.createdBy = primitives.createdBy
            checkListTemplate.updatedAt = primitives.updatedAt
            checkListTemplate.createdAt = primitives.createdAt
            await checkListTemplateRepository.save(checkListTemplate)
            await storeData()
            await eventBus.publish(template.pullDomainEvents())
        }catch (e) {
            console.error(e)
        }
    }

    const findAllCheckListTemplate = async ()=> {
        const checklistList = await checkListTemplateRepository.find()
        const checkAggre = checklistList.map(t => CheckListTemplate.fromPrimitives({
            createdAt: t.createdAt,
            createdBy: t.createdBy,
            global: t.global,
            guid: t.guid,
            items: t.items,
            name: t.name,
            updatedAt: t.updatedAt
        }))
        return checkAggre
    }

    const findByIdCheckListTemplate = async (guid:string) => {
        const t = await checkListTemplateRepository.findOneBy({guid})
        if (!t) return
        const checkAggre = CheckListTemplate.fromPrimitives({
            createdAt: t.createdAt,
            createdBy: t.createdBy,
            global: t.global,
            guid: t.guid,
            items: t.items,
            name: t.name,
            updatedAt: t.updatedAt
        })
        return checkAggre
    }
    return {
        putChecklistTemplate,
        findAllCheckListTemplate,
        findByIdCheckListTemplate
    }

}
