import 'reflect-metadata'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './locales'

import 'primereact/resources/themes/viva-light/theme.css'
import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import './Theme/Styles/reset.scss'
import './Theme/Styles/index.scss'

import {Capacitor} from '@capacitor/core'
import { App as CapacitorApp } from '@capacitor/app';
import {JeepSqlite} from 'jeep-sqlite/dist/components/jeep-sqlite';
import {CapacitorSQLite} from "@capacitor-community/sqlite";
import {sqliteConnection} from "./Context/Shared/Infrastructure/Persistence/Sqlite/SqliteConnection.ts";
import AppDbFactory from "./AppDbFactory.ts";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./reportWebVitals.ts";

if (process.env.NODE_ENV !== 'development') console.debug = (..._args: any) => {}
customElements.define('jeep-sqlite', JeepSqlite);

const rootRender = () => {
    ReactDOM.createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
    )
}
window.addEventListener('DOMContentLoaded', async () => {
    const platform = Capacitor.getPlatform();

    if (platform !== 'web') {
        console.debug('Configuring BackButton')
        CapacitorApp.addListener('backButton', ({canGoBack}) => {
            if(!canGoBack){
                void CapacitorApp.exitApp();
            } else {
                window.history.back();
            }
        });
    }

    if (platform === "web") {
        const jeepEl = document.createElement("jeep-sqlite");
        document.body.appendChild(jeepEl);
        await customElements.whenDefined('jeep-sqlite')
        await sqliteConnection.initWebStore()
    }

    // when using Capacitor, you might want to close existing connections,
    // otherwise new connections will fail when using dev-live-reload
    // see https://github.com/capacitor-community/sqlite/issues/106
    await CapacitorSQLite.checkConnectionsConsistency({
        dbNames: [], // i.e. "i expect no connections to be open"
        openModes: [],
    }).catch((e) => {
        // the plugin throws an error when closing connections. we can ignore
        // that since it is expected behaviour
        console.log(e);
        return {};
    });

    for (const connection of [AppDbFactory]) {
        try {
            if (!connection.isInitialized) {
                await connection.initialize();
                console.debug('TYPEORM INITIALIZED')
            }
            if (await connection.showMigrations()){
                console.debug('TYPEORM NEED MIGRATIONS')
                await connection.runMigrations({transaction: 'all'});
                console.debug('TYPEORM MIGRATED')
            }
            console.debug('TYPEORM COMPLETED')

        }catch (e) {
            const myError = e as Error
            console.error(myError.name)
            console.error(myError.message)
            console.error(myError.stack)
        }

    }

    if (platform === 'web') {
        // save the database from memory to store
        console.debug('TYPEORM STORE WEB Changes')
        try {
            await sqliteConnection.saveToStore('app-database');
        } catch (e) {
            console.error(e)
        }

    }
    rootRender();
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
