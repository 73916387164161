import {useContext, useState} from 'react'
import { Dialog } from 'primereact/dialog'
import { useCheckListQueries } from '../../Infrastructure/use/useCheckListQueries.tsx'
import { useForm } from 'react-hook-form'
import { CheckList } from '../../Domain/CheckList.tsx'
import { CheckListTemplate } from '../../../Template/Domain/CheckListTemplate.tsx'
import { useNavigate } from 'react-router-dom'
import MyInputText from '../../../../../Theme/Components/Input/MyInputText.tsx'
import { Button } from 'primereact/button'
import {AppContext} from "../../../../../AppContext.tsx";

export default function useFormCheckListFromTemplate() {
  const {account, trans,api} = useContext(AppContext)
  const [visibility, setVisibility] = useState(false)
  const [template, setTemplate] = useState<CheckListTemplate>()
  const { putCheckList:{mutate} } = useCheckListQueries({account, api})
  const navigate = useNavigate()
  const { control, handleSubmit } = useForm<{ name: string }>()
  const onHideHandler = () => {
    setVisibility(false)
  }
  const openDialog = (template: CheckListTemplate) => {
    setTemplate(template)
    setVisibility(true)
  }
  const formDialog = (
    <Dialog
      header={trans.t('New CheckList')}
      visible={visibility}
      onHide={() => {
        onHideHandler()
      }}
    >
      <form
        onSubmit={handleSubmit((data) => {
          mutate(CheckList.createFromTemplate(data.name, account.guid, template!))
          navigate('/checklist')
        })}
      >
        <div className="flex flex-column gap-1">
          <div>
            <MyInputText name={'name'} control={control} label={'Name'} rules={{'required': true}} />
          </div>
          <div className="flex justify-content-end">
            <Button>{trans.t('Create')}</Button>
          </div>
        </div>
      </form>
    </Dialog>
  )

  return { formDialog, openDialog }
}
