import {EventBus} from "../../../../Domain/Bus/EventBus.ts";
import {DomainEvent, DomainEventClass} from "../../../../Domain/Bus/DomainEvent.ts";
import {DomainEventSubscriber} from "../../../../Domain/Bus/DomainEventSubscriber.ts";
import {DomainEventMapping} from "../../../../Domain/Bus/DomainEventMapping.ts";
import EventEmitter from "eventemitter3";

export class InMemoryAsyncEventBus implements EventBus {
  private bus: EventEmitter

  constructor() {
    this.bus = new EventEmitter()
  }

  async start(): Promise<void> {
    return
  }

  async stop():Promise<void> {}

  async publish(events: DomainEvent[]): Promise<void> {
    console.debug('emitting', events)
    for (const event of events) {
      this.bus.emit(event.eventName, event.toPrimitive())
    }
  }

  addSubscribers(subscribers: Array<DomainEventSubscriber>) {
    for (const subscriber of subscribers) {
      for (const event of subscriber.subscribedTo()) {
        this.bus.on(event.EVENT_NAME, async (data:any) => {
          const eventClass = (event as unknown as DomainEventClass<{}>).fromPrimitives(data)
          await subscriber.on(eventClass)
        })
      }
    }
  }

  setDomainEventMapping(_domainEventMapping: DomainEventMapping): void {
    return
  }
}
