import {AccountInfo} from "../../../../../App.tsx";
import {useEffect, useState} from "react";
import {io, Socket} from "socket.io-client";
import {useCheckListApplication} from "../../../../ChekList/Execution/Application/useCheckListApplication.ts";
import {useQueryClient} from "@tanstack/react-query";

export interface RealtimeBus {
    realtime?: Socket
}

export const useRealtimeBus = (options: { account: AccountInfo }): RealtimeBus => {
    const [realtime, setRealtime] = useState<Socket>()
    const client = useQueryClient()
    const {findByIdCheckList} = useCheckListApplication()
    useEffect(() => {
        const socket = io(
            process.env.REACT_APP_SOCKET_SERVER_HOST,
            {
                auth:
                    {
                        access_token: options.account.credentials?.accessToken,
                        user_guid: options.account.onlineMode ? options.account.guid : undefined
                    }
            }
        )
        socket.on('connect', () => {
            console.debug(`Socket connected ${socket.id} - ${socket.auth['access_token']}`)
        })
        socket.connect()
        socket.on('CheckListEventApplied', async (data) => {
            console.debug('Socket Received CheckListEventApplied', data, (new Date()).toISOString())
            const guid: string = data.aggregate_guid
            const updateAt: Date = new Date(data.updated_at)
            const checkList = await findByIdCheckList(guid)
            console.debug('Socket test Checklist update', !!checkList, checkList ? checkList.updatedAt.getTime() < updateAt.getTime() : undefined)
            if (!checkList || checkList.updatedAt.getTime() < updateAt.getTime()) {
                console.debug('Socket refresh query CheckList')
                await client.invalidateQueries({queryKey: ['CheckList']})
                if (checkList) await client.invalidateQueries({queryKey: ['CheckList', checkList.guid.value]})
            }
        })
        setRealtime(socket)
        return () => {
            realtime?.close()
        }
    }, [setRealtime]);

    useEffect(() => {
        console.debug('Socket Check reconnection needed', realtime?.auth['access_token'], options.account?.credentials?.accessToken)
        const realtimeToken = realtime?.auth['access_token']
        const userToken = options.account?.credentials?.accessToken
        if (realtime && realtimeToken !== userToken) {
            realtime.auth = {
                access_token: options.account.credentials?.accessToken,
                user_guid: options.account.onlineMode ? options.account.guid : undefined
            }
            realtime.disconnect()
            realtime.connect()
        }
    }, [options.account]);
    return {
        realtime
    }
}
