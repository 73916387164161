import {AppDataSource} from "./Context/Shared/Infrastructure/Persistence/TypeOrm/AppDataSource";
import {
    CheckListTemplateEntity
} from "./Context/ChekList/Template/Infrastructure/persistence/TypeOrm/CheckListTemplate.Entity";
import {CheckListEntity} from "./Context/ChekList/Execution/Infrastructure/persistence/TypeOrm/CheckList.Entity";
import {CheckList1699621913368} from "./migrations/1699621913368-CheckList.ts";
import {CheckListTemplate1699613943959} from "./migrations/1699613943959-CheckListTemplate.ts";
import {AccountOffline1699798596146} from "./migrations/1699798596146-AccountOffline.ts";
import {AccountEntity} from "./Context/Account/Infrastructure/Persistence/TypeOrm/AccountEntity.ts";
import {AccountWithCredentials1702152267181} from "./migrations/1702152267181-AccountWithCredentials.ts";

export default AppDataSource(
    [
        CheckListTemplateEntity,
        CheckListEntity,
        AccountEntity
    ],
    [
        CheckListTemplate1699613943959,
        CheckList1699621913368,
        AccountOffline1699798596146,
        AccountWithCredentials1702152267181
    ]
)
