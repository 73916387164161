import {PropsWithoutRef, ReactElement} from 'react'
import {Icon} from "../../../../../../Theme/Components/Icon/Icon.tsx";
import {useLocation, useNavigate} from "react-router-dom";
import {classNames} from "../../classNames.ts";

export default function MobileMenu({onMenu}:PropsWithoutRef<{ onMenu:()=>void }>): ReactElement {
    const buttonSize = 32
    const navigate = useNavigate()
    const location = useLocation()
    return (<>
        <div className="flex justify-content-between bg-gray-50 align-items-center p-2 border-top-1 border-gray-400 px-4 shadow-1 sticky bottom-0"
             style={{height: 60}}>
            <div className={classNames({'text-primary': location.pathname === '/'})} onClick={() => {
                navigate('/')
            }}><Icon classname="flex" name={'home'} size={buttonSize}/></div>
            <div className={classNames({'text-primary': location.pathname === '/checklist'})} onClick={() => {
                navigate('/checklist')
            }}><Icon classname="flex" name={'checklist'} size={buttonSize}/></div>
            <div className={classNames({'text-primary': location.pathname === '/checklist/template'})} onClick={() => {
                navigate('/checklist/template')
            }}><Icon classname="flex" name={'checklistTemplate'} size={buttonSize}/></div>
            <div onClick={onMenu}><Icon classname="flex" name={'menu'} size={buttonSize}/></div>
        </div>
    </>)
}
