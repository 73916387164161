import {MouseEventHandler, ReactElement} from 'react'
import { FaDna } from 'react-icons/fa6'
import {
  LiaCheckCircleSolid,
  LiaCodeSolid,
  LiaEditSolid,
  LiaHistorySolid,
  LiaIdCard,
  LiaPlusSolid,
  LiaUserSolid,
} from 'react-icons/lia'
import { GoSignIn, GoSignOut } from 'react-icons/go'
import {
  PiArrowDown, PiArrowLeft,
  PiArrowUp, PiCalendar, PiCaretDown, PiCaretRight, PiCheckSquare, PiDotsThreeOutlineVertical, PiFloppyDisk,
  PiGlobeHemisphereWest, PiHouse, PiInfo, PiList, PiListChecks, PiListMagnifyingGlass,
  PiPencilLineLight,
  PiPlayFill, PiQueue, PiSquare, PiTrash,
  PiUser, PiWifiSlashBold,
} from 'react-icons/pi'

interface IconProps {
  name: keyof typeof icons
  size?: number | string
  classname?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

const icons = {
  signOut: GoSignOut,
  signIn: GoSignIn,
  adn: FaDna,
  add: LiaPlusSolid,
  code: LiaCodeSolid,
  user: LiaUserSolid,
  profile: LiaIdCard,
  setting: PiPencilLineLight,
  edit: LiaEditSolid,
  disk: PiFloppyDisk,
  trash: PiTrash,
  history: LiaHistorySolid,
  approval: LiaCheckCircleSolid,
  personalItem: PiUser,
  globalItem: PiGlobeHemisphereWest,
  play: PiPlayFill,
  caret: PiCaretDown,
  caretRight: PiCaretRight,
  up: PiArrowUp,
  home: PiHouse,
  menu: PiList,
  checklist: PiListChecks,
  checklistTemplate: PiQueue,
  checklistDetails: PiListMagnifyingGlass,
  down: PiArrowDown,
  squareChecked: PiCheckSquare,
  squareUnchecked: PiSquare,
  info: PiInfo,
  arrowLeft: PiArrowLeft,
  networkOff: PiWifiSlashBold,
  calendar: PiCalendar,
  menuDot: PiDotsThreeOutlineVertical
}

export function Icon({ name, size = '1.2rem', classname = '', onClick }: IconProps): ReactElement {
  const Component = icons[name] || <>Error</>
  return (
    <div className={classname} onClick={onClick}>
      <Component size={size} />
    </div>
  )
}
