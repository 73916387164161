import {ReactElement, useContext} from 'react'
import {NavLink, useRouteError} from "react-router-dom";
import PageLayout from "../../../../../Theme/Components/Page/PageLayout.tsx";
import {AppContext} from "../../../../../AppContext.tsx";

export default function PageError(): ReactElement {
    const {trans} = useContext(AppContext)
    const error = useRouteError()
    console.error(error)
    return (<PageLayout>
        <div className="p-2 pt-8">
            <div className="font-bold text-2xl text-red-500 pb-3">Opps</div>
            <div>{trans.t('Sorry, but something are not working correctly, try to reload the app.')}</div>
            <div className="pt-2">{trans.t('Go to')} <NavLink to="/">{trans.t('home')}</NavLink>.</div>
        </div>
    </PageLayout>)

}
