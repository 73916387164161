import {KeyboardEventHandler, PropsWithoutRef, ReactElement, useEffect, useState} from 'react'
import style from './InputTextInline.module.scss'
import {classNames} from "../../../classNames.ts";
import {Icon} from "../../../../../../../Theme/Components/Icon/Icon.tsx";

interface InputTextInlineProps {
    value: string
    onChange: (value: string) => void
    autoSave?: boolean,
    className?: string,
    disabled?: boolean,
    placeholder?: string
}

export default function InputTextInline({
                                            value,
                                            onChange,
                                            autoSave,
                                            className,
                                            disabled, placeholder
                                        }: PropsWithoutRef<InputTextInlineProps>): ReactElement {
    const [focused, setFocused] = useState(false)
    const [transitValue, setTransitValue] = useState(value)
    useEffect(() => {
        setTransitValue(value)
    }, [value]);
    const handlerSave = () => {
        setFocused(false)
        if (transitValue === value) return
        onChange(transitValue)
        setTransitValue(value)
    }

    const handlerBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setFocused(false)
            if (autoSave) handlerSave()
            setTransitValue(value)
        }
    }

    const handlerKeyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
        console.debug(e.code)
        if (e.code === 'Enter') {
            handlerSave()
        }
        if (e.code === 'Escape') {
            setFocused(false)
            setTransitValue(value)
        }
    }

    const handlerFocus = () => {
        if (disabled) return
        setFocused(true)
    }

    return <>
        {!focused && <div className={classNames('flex flex-grow-1', {'cursor-pointer': !disabled}, className)}
                          onClick={handlerFocus}>{placeholder||value}</div>}
        {focused && <div tabIndex={0} className={classNames("flex flex-grow-1", style.InputInlineContainer)}
                         onBlur={handlerBlur}>
            <input className={classNames('flex flex-grow-1', className)} autoFocus type="text" value={transitValue}
                   onKeyUp={handlerKeyUp} placeholder={placeholder}
                   onChange={(e) => setTransitValue(e.target.value)}/>
            {!autoSave && <button onClick={handlerSave}><Icon classname="flex" name={'disk'} size={'1.5rem'}/></button>}
        </div>}
    </>
}
