import {AccountGuidChangedEvent} from "../../../Account/Domain/Events/AccountGuidChangedEvent.tsx";
import {Constructor, DomainEventSubscriber} from "../../../Shared/Domain/Bus/DomainEventSubscriber.ts";
import {DomainEvent} from "../../../Shared/Domain/Bus/DomainEvent.ts";
import {useCheckListApplication} from "./useCheckListApplication.ts";
import {CheckList} from "../Domain/CheckList.tsx";

export class ChangeCheckListSubscriber implements DomainEventSubscriber<AccountGuidChangedEvent> {
    eventPath: string = AccountGuidChangedEvent.name;

    constructor(protected readonly application: ReturnType<typeof useCheckListApplication>) {
    }

    async on(domainEvent: AccountGuidChangedEvent): Promise<void> {
        console.debug(`[${ChangeCheckListSubscriber.name}] is executed with event: `, domainEvent)
        const checkLists = await this.application.findAllCheckList()
        console.debug(`[${ChangeCheckListSubscriber.name}] to change: ${checkLists.length}`)
        for (const checkList of checkLists) {
            console.debug(`[${ChangeCheckListSubscriber.name}] to changing: ${checkLists.length}`)
            if (domainEvent.content.oldGuid === checkList.createdBy)
                await this.application.putCheckList(CheckList.recreateFromOffline(checkList, domainEvent.content.newGuid))
        }
    }

    subscribedTo(): Constructor<DomainEvent<any>>[] {
        return [AccountGuidChangedEvent];
    }

}
