import {MigrationInterface, QueryRunner, Table} from "typeorm"

export class CheckListTemplate1699613943959 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.createTable(new Table({
            name: 'checklist_template',
            columns: [{name: 'guid', type: 'varchar', isPrimary: true}, {name: 'name', type: 'varchar'}, {
                name: 'global',
                type: 'varchar'
            }, {name: 'created_at', type: 'integer'}, {
                name: 'updated_at',
                type: 'integer'
            }, {name: 'created_by', type: 'varchar'}, {
                name: 'items',
                type: 'text'
            }]
        }))
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.dropTable("checklist_template")
    }

}
