import {ReactElement, useContext, useEffect, useState} from 'react'
import ItemCheckList from './ItemCheckList.tsx'
import { useNavigate } from 'react-router-dom'
import { useCheckListTemplate } from '../../../Infrastructure/use/useCheckListTemplate.tsx'
import { CheckListTemplate } from '../../../Domain/CheckListTemplate.tsx'
import useFormCheckListFromTemplate from '../../../../Execution/Pages/Components/UseFormCheckListFromTemplate.tsx'
import EmptyListAdvisor from "../../../../Share/Infrastructure/Components/EmptyList/EmptyListAdvisor.tsx";
import {AppContext} from "../../../../../../AppContext.tsx";

export default function ListCheckLists(): ReactElement {
  const [list, setList] = useState<CheckListTemplate[]>([])
  const {getListChecklistTemplates:{data, dataUpdatedAt}} = useCheckListTemplate()
  const {formDialog, openDialog} = useFormCheckListFromTemplate()
  const navigate = useNavigate()
  useEffect(() => {
    console.debug('updating data')
    if (!data) return setList([])
    setList(data)
  }, [data, dataUpdatedAt])
  const {trans} = useContext(AppContext)
  return (
    <div className='flex flex-column gap-3 mt-3'>
      {!list.length && <EmptyListAdvisor message={trans.t('No Templates')}/>}
      {list.map((i) => {
        return (
          <ItemCheckList
            key={i.guid.value}
            guid={i.guid.value}
            name={i.name}
            global={i.global}
            template={i as CheckListTemplate}
            onEdit={(guid) => {
              navigate(`/checklist/template/${guid}`)
            }}
            onRunTemplate={() => {
              openDialog(i as CheckListTemplate)
            }}
          />
        )
      })}
      {formDialog}
    </div>
  )
}
