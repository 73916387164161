import {Column, Entity, PrimaryColumn} from "typeorm";
import {jsonTransform} from "../../../../../Shared/Infrastructure/Persistence/TypeOrm/JsonTransform.ts";
import {dateTransform} from "../../../../../Shared/Infrastructure/Persistence/TypeOrm/DateTransform.ts";
import {CheckListTaskPrimitives} from "../../../Domain/CheckListTaskPrimitives.tsx";

@Entity('checklist')
export class CheckListEntity {
    @PrimaryColumn({type: 'varchar'})
    guid!: string
    @Column({type: 'varchar'})
    name!: string
    @Column({type: 'varchar', name: 'template_guid'})
    templateGuid?: string | null
    @Column({type: 'text', transformer: jsonTransform<CheckListTaskPrimitives[]>()})
    tasks!: CheckListTaskPrimitives[]
    @Column({type: 'integer', name: 'due_at', transformer: dateTransform, nullable: true})
    dueAt!: Date | null
    @Column({type: 'integer', name: 'completed_at', transformer: dateTransform, nullable: true})
    completedAt!: Date | null
    @Column({type: 'integer', name: 'archived_at', transformer: dateTransform, nullable: true})
    archivedAt!: Date | null
    @Column({type: 'integer', name: 'created_at', transformer: dateTransform})
    createdAt!: Date
    @Column({type: 'varchar', name: 'created_by'})
    createdBy!: string
    @Column({type: 'integer', name: 'updated_at', transformer: dateTransform})
    updatedAt!: Date
}
