import {CheckListTaskDTO} from './CheckListTaskDTO.tsx'
import {Entity} from "../../../Shared/Domain/Aggregate/Entity.ts";
import {Uuid} from "../../../Shared/Domain/ValueObject/Uuid.ts";
import {CheckListTaskPrimitives} from "./CheckListTaskPrimitives.tsx";

export class CheckListTask extends Entity<CheckListTask, CheckListTaskPrimitives> {
    readonly guid: Uuid
    readonly itemGuid?: string
    readonly sort: number
    readonly text: string
    readonly performedAt?: Date

    constructor(data: CheckListTaskDTO) {
        super()
        this.guid = data.guid
        this.sort = data.sort
        this.text = data.text
        this.itemGuid = data.itemGuid
        this.performedAt = data.performedAt
    }

    sortComparator(other: CheckListTask): number {
        if (!!this.performedAt !== !!other.performedAt) {
            if (this.performedAt) return 1
            return -1
        }
        return this.sort - other.sort
    }

    toPrimitives(): CheckListTaskPrimitives {
        return {
            guid: this.guid.value,
            itemGuid: this.itemGuid,
            performedAt: this.performedAt,
            sort: this.sort,
            text: this.text
        };
    }

    static fromPrimitives(primitives: CheckListTaskPrimitives): CheckListTask {
        return new CheckListTask({
            guid: new Uuid(primitives.guid),
            itemGuid: primitives.itemGuid,
            performedAt: primitives.performedAt,
            sort: primitives.sort,
            text: primitives.text
        })
    }
}
