import {createContext, PropsWithChildren, ReactElement, RefObject, useEffect, useRef, useState} from "react";
import {AccountInfo} from "./App";
import {Toast} from "primereact/toast";
import AppDbFactory from "./AppDbFactory.ts";
import {
    CreateCheckListTemplateSubscriber
} from "./Context/ChekList/Template/Application/create/CreateCheckListTemplateSubscriber.ts";
import {InMemoryAsyncEventBus} from "./Context/Shared/Infrastructure/Bus/Event/InMemory/InMemoryAsyncEventBus.ts";
import {useScreen} from "./Context/Shared/Infrastructure/React/useScreen.ts";
import {EventBus} from "./Context/Shared/Domain/Bus/EventBus.ts";
import {DataSource} from "typeorm";
import {useAccountApplication} from "./Context/Account/Application/useAccountApplication.tsx";
import {i18n} from "i18next";
import {i18next} from './locales'
import {
    ApiResponse,
    HttpApiCredentials,
    HttpApiRepository
} from "./Context/Shared/Domain/Persistence/HttpApiRepository.ts";
import {FetchHttpApiRepository} from "./Context/Shared/Infrastructure/Persistence/Fetch/FetchHttpApiRepository.ts";

export interface AppContextData {
    account: AccountInfo
    updateAccount: () => Promise<void>
    screen: ReturnType<typeof useScreen>
    eventBus: EventBus
    refToast?: RefObject<Toast>
    db: DataSource
    trans: i18n
    api: HttpApiRepository
}

export const AppContext: ReturnType<typeof createContext<AppContextData>> = createContext<AppContextData>(null!)

export default function AppProvider({children}: PropsWithChildren): ReactElement {
    const initialized = useRef(false)
    const [account, setAccount] = useState<AccountInfo>({name: '', guid: 'APP_USER', onlineMode: false})
    const screen = useScreen()
    const eventBus = new InMemoryAsyncEventBus()
    const db = AppDbFactory
    const {findAppAccount, putAccount, renameAccountGuid} = useAccountApplication({db, eventBus})
    const refreshAccount = async () => {
        const user = await findAppAccount()
        setAccount({
            guid: user!.guid.value,
            name: user!.name,
            onlineMode: user!.onlineMode,
            credentials: user!.credentials
        })
    }

    const receiveCredentials = async (response: { access_token: string, refresh_token: string }) => {
        const account = await findAppAccount()
        if (!account) return
        const newAccount = account.receiveCredentials({
            access_token: response.access_token,
            refresh_token: response.refresh_token
        })
        await putAccount(newAccount)
        console.debug('putAccount:', newAccount)
        setAccount({
            guid: newAccount.guid.value,
            name: newAccount.name,
            onlineMode: newAccount.onlineMode,
            credentials: newAccount.credentials
        })
        // entry in mode online and server send a correct Guid for the account
        if (!account.guid.equals(newAccount.guid)) {
            console.debug(`[Account Application] different user guid detected`)
            await renameAccountGuid(newAccount.guid, account.guid)
        }
        return newAccount
    }


    const api = new FetchHttpApiRepository({
        loadCredentials: async () => {
            const account = await findAppAccount()
            if (!account?.credentials) throw new Error(`Forbidden Not Credentials available ${account?.name}`)
            return {
                refreshToken: account.credentials.refreshToken,
                accessToken: account.credentials.accessToken,
                expireAt: account.credentials.expireAt,
                userGuid: account.guid.value
            }
        },
        storeCredentials: async (response: ApiResponse): Promise<HttpApiCredentials> => {
            console.debug('storeCredentials', response)
            const newAccount = await receiveCredentials({
                access_token: response.data.access_token,
                refresh_token: response.data.refresh_token
            })
            return {
                refreshToken: newAccount!.credentials!.refreshToken,
                accessToken: newAccount!.credentials!.accessToken,
                expireAt: newAccount!.credentials!.expireAt,
                userGuid: newAccount!.guid.value
            };
        }

    })
    const app: AppContextData = {
        account,
        updateAccount: refreshAccount,
        refToast: useRef<Toast>(null),
        screen,
        eventBus: eventBus,
        db,
        trans: i18next,
        api,
    }
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            refreshAccount()
        }
    }, []);
    eventBus.addSubscribers([new CreateCheckListTemplateSubscriber()])
    return (<AppContext.Provider value={app}>
        {initialized.current && children}
    </AppContext.Provider>)
}
