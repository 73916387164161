import {ReactElement} from 'react'
import styles from './ChecklistStatus.module.scss'
import {classNames} from "../../../../../Shared/Infrastructure/React/classNames.ts";

interface ChecklistStatusSquareProps {
    archivedAt?: Date
    completedAt?: Date
    isExpired: boolean
}

export default function CheckListStatusSquare({
                                                  isExpired,
                                                  archivedAt,
                                                  completedAt
                                              }: ChecklistStatusSquareProps): ReactElement {
    const stateString = completedAt ? 'completed' : archivedAt ? 'archived' : isExpired ? 'expired' : 'pending'
    const stateStyle = styles[stateString]
    return (<div className={classNames(styles.checklistStatus, stateStyle)}/>)
}
