import {ValueTransformer} from "typeorm";

export const booleanTransform: ValueTransformer = {
    to(value: boolean): string {
        return value ? 'true' : 'false'
    },
    from(value: string): boolean {
        return value === 'true';
    }
}
