import {ReactElement, useContext, useEffect, useState} from 'react'
import PageLayout from '../../../../Theme/Components/Page/PageLayout.tsx'
import {useParams} from 'react-router-dom'
import {useCheckListQueries} from '../Infrastructure/use/useCheckListQueries.tsx'
import {CheckList} from '../Domain/CheckList.tsx'
import CheckListTaskItem from './Components/CheckListTaskItem.tsx'
import useCheckListTaskInfoDialog from './Components/UseCheckListTaskInfoDialog.tsx'
import CheckListStatusSquare from "./Components/Status/CheckListStatusSquare.tsx";
import DueFieldIndicator from "./Components/DueField/DueFieldIndicator.tsx";
import {Divider} from "primereact/divider";
import ButtonMenu from "../../../Shared/Infrastructure/React/Components/Menu/BtnMenu/ButtonMenu.tsx";
import {Icon} from "../../../../Theme/Components/Icon/Icon.tsx";
import {useFormExpireAt} from "./Components/useFormExpireAt.tsx";
import {useFormName} from "./Components/useFormName.tsx";
import InputTextInline from "../../../Shared/Infrastructure/React/Components/Form/TextInline/InputTextInline.tsx";
import {CheckListTask} from "../Domain/CheckListTask.tsx";
import {Uuid} from "../../../Shared/Domain/ValueObject/Uuid.ts";
import {AppContext} from "../../../../AppContext.tsx";

export default function CheckListPage(): ReactElement {
    const [checkList, setCheckList] = useState<CheckList>()
    const context = useContext(AppContext)
    const {guid} = useParams()
    const {taskInfoDialog, openTaskInfoDialog} = useCheckListTaskInfoDialog()
    const {dialog, openForm} = useFormExpireAt({checklist: checkList!})
    const {nameDialog, openFormName, nameInline} = useFormName({checklist: checkList!})
    const {
        getChecklist: {data, dataUpdatedAt, error},
        putCheckList: {mutate},
    } = useCheckListQueries({account: context.account, api: context.api, guid: guid})
    useEffect(() => {
        if (!data) return setCheckList(undefined)
        setCheckList(data)
    }, [data, dataUpdatedAt])

    const menu = [
        {
            label: 'edit',
            action: () => openFormName(),
            icon: <Icon classname="flex" name={'trash'}/>
        },
        {
            label: 'Expire',
            action: () => openForm(),
            icon: <Icon classname="flex" name={'calendar'}/>
        },
        {
            label: 'remove',
            action: () => alert('remove')
        }
    ]
    return (
        <PageLayout>
            {checkList && !error && (
                <div className='flex flex-column overflow-hidden'>
                    <div className="py-2 flex justify-content-between align-items-center">{nameInline}
                        <ButtonMenu items={menu}/></div>
                    <div className="flex gap-2 align-items-center">
                        <CheckListStatusSquare isExpired={checkList.isDue} archivedAt={checkList.archivedAt}
                                               completedAt={checkList.completedAt}/>
                        <DueFieldIndicator disabled={!!checkList.completedAt || checkList.isArchived}
                                           isExpired={checkList.isDue} isCompleted={!!checkList.completedAt}
                                           dueAt={checkList.dueAt} onClick={openForm}/>
                    </div>
                    <Divider/>
                    <div className="pb-2">
                        {!checkList.isArchived && !checkList.completedAt &&
                            <InputTextInline className="text-xl" placeholder={'+ New subtask'} value={''}
                                             onChange={(value) => {
                                                 let nextSort = 0
                                                 for (const task of checkList.tasks) {
                                                     if (task.sort >= nextSort) nextSort = task.sort + 1
                                                 }
                                                 const newTask = new CheckListTask({
                                                     guid: Uuid.random(),
                                                     text: value,
                                                     sort: nextSort
                                                 })
                                                 const newChecklist = checkList.addNewTask(newTask)
                                                 mutate(newChecklist)
                                             }}/>}
                    </div>
                    <div className='flex flex-column gap-2 flex-grow-1 overflow-y-auto'>
                        {checkList.tasks.sort((a, b) => a.sortComparator(b)).map((task) => {
                            return (
                                <CheckListTaskItem
                                    key={task.guid.value}
                                    task={task}
                                    onInfoClick={(task) => {
                                        openTaskInfoDialog(task)
                                    }}
                                    onChangeText={(task, text) => {
                                        const checklistModified = checkList.changeNameTask(task, text)
                                        mutate(checklistModified)
                                    }}
                                    onToggleCheck={(task) => {
                                        const modifiedCheckList = task.performedAt
                                            ? checkList.unCheckTask(task)
                                            : checkList.checkTask(task)
                                        console.debug('PreToggleCheck', modifiedCheckList)
                                        mutate(modifiedCheckList)
                                    }}
                                />
                            )
                        })}

                    </div>
                    {taskInfoDialog}
                </div>
            )}
            {dialog}
            {nameDialog}
        </PageLayout>
    )
}
