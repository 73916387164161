export default {
    offline: 'Offline',
    "Task info": "Info Tarea",
    "Text": "Texto",
    "Performed At": "Realizado a las",
    "Completed": "Completado",
    "Tasks": "Tareas",
    "New Template": "Nueva Plantilla",
    "Add": "Añadir",
    "Header": "Cabecera",
    "New CheckList": "Nueva CheckList",
    "Create": "Crear",
    "save": "Guardar",
    "Sorry, but something are not working correctly, try to reload the app.": "Disculpa, pero algo no esta funcionando correctamente, intenta recargar la app.",
    "Go to": "Ir a",
    "home": "Inicio",
    "Page not found.": "Página no encontrada.",
    "No Templates": "Sin Plantillas",
    "No CheckLists": "Sin Checklists"
}
