import TemplateDialog from '../../Pages/Components/Dialog/TemplateDialog.tsx'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export function useCreateCheckListTemplate() {
  const [visible, setVisible] = useState(false)
  const [guid, setGuid] = useState<string|undefined>(undefined)
  const navigate = useNavigate()

  const CreateCheckListTemplateForm = <TemplateDialog guid={guid} visible={visible} onSuccess={(guid) => {
    setVisible(false)
    setGuid(undefined)
    if (guid){
      console.debug(`navigating to /checklist/template/${guid}`)
      navigate(`/checklist/template/${guid}`)
    }
  }} />
  const openEditForm = (guid: string) => {
    setGuid(guid)
    setVisible(true)
  }

  const openForm = () => {
    setGuid(undefined)
    setVisible(true)
  }
  return { openEditForm, openForm, CreateCheckListTemplateForm }
}
