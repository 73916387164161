import {ReactElement, useContext} from 'react'
import {Icon} from "../../../../../../Theme/Components/Icon/Icon.tsx";
import {shortDateFormat} from "../../../../../Shared/Infrastructure/Date/format.ts";
import {classNames} from "../../../../../Shared/Infrastructure/React/classNames.ts";
import {AppContext} from "../../../../../../AppContext.tsx";
import {capitalizeAllString} from "../../../../../Shared/Infrastructure/String/StringCase.ts";

interface DueFieldIndicatorProps {
    dueAt?: Date
    isExpired: boolean
    isCompleted: boolean
    onClick?: (e) => void
    disabled?: boolean
}

export default function DueFieldIndicator({
                                              dueAt,
                                              isExpired,
                                              isCompleted,
                                              onClick,
                                              disabled
                                          }: DueFieldIndicatorProps): ReactElement {
    const {trans: {t}} = useContext(AppContext)
    const dateString = shortDateFormat(dueAt) || capitalizeAllString(t('no expire'))
    const hasDueAt = !!dueAt
    return (<div
        onClick={(e) => {
            if(!disabled) onClick?.call(null, e)
        }}
        className={classNames("flex align-items-center gap-1 border-round p-1 px-2 bg-gray-100", {
            'text-gray-500': !hasDueAt,
            'text-red-500': isExpired && !isCompleted,
            'bg-red-100': isExpired && !isCompleted,
            'cursor-pointer': !!onClick
        })}
    >
        <Icon classname="flex" name={'calendar'} size={24}/>
        <div className="flex">{dateString}</div>
    </div>)
}
