import { PropsWithChildren, ReactElement, useContext } from 'react'
import { Icon } from '../Icon/Icon.tsx'
import { useNavigate, useOutlet } from 'react-router-dom'
import { classNames } from '../../../Context/Shared/Infrastructure/React/classNames.ts'
import {AppContext} from "../../../AppContext.tsx";

export default function PageDoubleLayout({
  children,
  waitingData = true,
  returnNavigate,
  header
}: PropsWithChildren<{
  waitingData?: unknown | undefined
  returnNavigate?: string,
  header?:ReactElement
}>): ReactElement {
  const navigate = useNavigate()
  const {
    screen: { screenUpTo,breakpoints },
  } = useContext(AppContext)
  const outlet = useOutlet()
  const someTool = !!returnNavigate
  const doubleVisible = screenUpTo('md')
  return (
    <div
      className={classNames('flex flex-grow-1 flex-column overflow-auto w-full h-full', [
        screenUpTo('xl') ? 'w-8' : screenUpTo('md') ? 'w-10' : undefined,
      ])}
      style={{maxWidth: breakpoints.lg}}
    >
      {someTool && (
        <div className='sm:hidden p-2'>
          {!!returnNavigate && (
            <div
              onClick={() => {
                navigate(returnNavigate)
              }}
            >
              <Icon size={'2rem'} name={'arrowLeft'} />
            </div>
          )}
        </div>
      )}
      <div>{header}</div>
      {!!waitingData && (
        <div className='flex gap-5 h-full'>
          <div
            className={classNames(
              'flex-column p-2 w-full overflow-auto',
              [!doubleVisible && !!outlet ? 'hidden' : 'flex'],
              {
                'w-6': doubleVisible,
              },
            )}
          >
            {children}
          </div>
          <div className={classNames('h-full py-4', {hidden: !doubleVisible})}>
            <div className='border-left-1 h-full'></div>
          </div>
          <div
            className={classNames('flex-column w-full', { 'w-6': doubleVisible }, [
              !doubleVisible && !outlet ? 'hidden' : 'flex',
            ])}
          >
            {outlet}
          </div>
        </div>
      )}
    </div>
  )
}
