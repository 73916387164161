import {PropsWithoutRef, ReactElement, useContext} from 'react'
import {useForm} from 'react-hook-form'
import {Button} from 'primereact/button'
import MyInputText from '../../../../../../Theme/Components/Input/MyInputText.tsx'
import {useCheckListTemplate} from '../../../Infrastructure/use/useCheckListTemplate.tsx'
import {CheckListTemplate} from '../../../Domain/CheckListTemplate.tsx'
import {Uuid} from "../../../../../Shared/Domain/ValueObject/Uuid.ts";
import {AppContext} from "../../../../../../AppContext.tsx";

export default function TemplateForm({onSuccess}: PropsWithoutRef<{
    guid?: string
    onSuccess?: (guid: string) => void
}>): ReactElement {

    const {createCheckListTemplate: {mutate}} = useCheckListTemplate({
        onSuccess: (guid) => {
            if (onSuccess) onSuccess(guid)
        }
    })

    const {account,trans} = useContext(AppContext)
    const {control, handleSubmit} = useForm<{ name: string }>({defaultValues: {name: ''}})

    return (
        <form
            onSubmit={handleSubmit((fields) => {
                console.debug('onSubmit CheckListTemplate Form')
                const now = new Date();
                const template = CheckListTemplate.create({
                    createdAt: now,
                    createdBy: account.guid,
                    global: false,
                    guid: Uuid.random(),
                    name: fields.name,
                    updatedAt: now
                })
                mutate(template)
            })}
        >
            <div>
                <MyInputText name={'name'} control={control} rules={{'required': true}}/>
            </div>
            <div>
                <Button type={'submit'} label={trans.t('save')}/>
            </div>
        </form>
    )
}
