import {PropsWithoutRef, ReactElement} from 'react'
import {Control, Controller} from 'react-hook-form'
import {InputText} from 'primereact/inputtext'
import {classNames} from '../../../Context/Shared/Infrastructure/React/classNames.ts'

export default function MyInputText({
                                        control,
                                        defaultValue,
                                        readOnly,
                                        disabled,
                                        name,
                                        className,
                                        floatLabel,
                                        label,
                                        onEnter,
                                        autoFocus,
                                        rules
                                    }: PropsWithoutRef<{
    control?: Control<any>
    name: string
    label?: string
    defaultValue?: string | number
    floatLabel?: boolean
    readOnly?: boolean
    disabled?: boolean
    className?: string
    autoFocus?: boolean
    onEnter?: () => void
    rules?: Record<string, any>
}>): ReactElement {
    return (
        <Controller
            rules={rules}
            render={({field, fieldState}) => {
                return (
                    <>
                        <label
                            htmlFor={field.name}
                            className={classNames({
                                'p-error': fieldState.invalid,
                                'ml-1': !floatLabel,
                                'mb-2': !floatLabel,
                            })}
                        >
                            {!floatLabel && label}
                        </label>
                        <span className='flex flex-grow-1 p-float-label'>
              <InputText
                  className={classNames(className, {'p-invalid': fieldState.invalid})}
                  onChange={(e) => {
                      e.stopPropagation()
                      field.onChange(e.target.value)
                  }}
                  ref={field.ref}
                  value={field.value || defaultValue || ''}
                  disabled={disabled}
                  autoFocus={autoFocus}
                  readOnly={readOnly}
                  style={{width: '100%'}}
                  onKeyUp={(event) => {
                      event.stopPropagation()
                      if (event.code === 'Enter') onEnter?.call(null)
                  }}
                  name={field.name}
              />
                            {floatLabel && <label htmlFor={name}>{label}</label>}
            </span>
                        {fieldState.error?.message ? (
                            <small className='p-error'>{fieldState.error.message[0]}</small>
                        ) : (
                            <small className='p-error'>&nbsp;</small>
                        )}
                    </>
                )
            }}
            name={name}
            control={control}
        />
    )
}
