import {DomainEvent, DomainEventPrimitive} from "../../../Shared/Domain/Bus/DomainEvent.ts";
import {CheckListTemplateCreate} from "./CheckListTemplate.tsx";

export interface CheckListTemplateCreatedEventPrimitives extends Omit<CheckListTemplateCreate, 'guid'> {
    readonly guid: string
}

export class CheckListTemplateCreatedEvent extends DomainEvent<CheckListTemplateCreatedEventPrimitives> {
    static EVENT_NAME = 'CheckListTemplateCreated'

    static create(data: CheckListTemplateCreatedEventPrimitives): CheckListTemplateCreatedEvent {
        return new CheckListTemplateCreatedEvent(CheckListTemplateCreatedEvent.EVENT_NAME, data.guid, data)
    }

    static fromPrimitives(primitives: DomainEventPrimitive<CheckListTemplateCreatedEventPrimitives>): CheckListTemplateCreatedEvent {
        return new CheckListTemplateCreatedEvent(primitives.eventName, primitives.aggregateId, primitives.content, primitives.eventId, primitives.occurredOn)
    }

    toPrimitive(): DomainEventPrimitive<CheckListTemplateCreatedEventPrimitives> {
        return {
            aggregateId: this.aggregateId,
            content: this.content,
            eventId: this.eventId,
            eventName: this.eventName,
            occurredOn: this.occurredOn
        };
    }
}
