import {PropsWithoutRef, ReactElement, useEffect, useState} from 'react'
import {Icon} from "../Icon/Icon.tsx";
import {useOffline} from "../../../Context/Shared/Infrastructure/React/useOffline.tsx";
import {Dialog} from "primereact/dialog";

export default function OfflineInfo({size}: PropsWithoutRef<{ size?: number }>): ReactElement {
    const {offline} = useOffline()
    const [seeInfo, setSeeInfo] = useState(false)
    useEffect(() => {
        if (!offline) setSeeInfo(false)
    }, [offline]);
    return <>
        {offline && <div className="m-2" onClick={() => {setSeeInfo(true)}}><Icon classname='p-error' size={size || 28} name={'networkOff'}/></div>}
        <Dialog visible={seeInfo} header={'Offline Mode'} onHide={() => {setSeeInfo(false)}} position={'top'}>
            <p className="m-0">
                You are lost the internet connection, the app are now in offline mode. You can follow working. When the device recover to online mode it will synchronize all data.
            </p>
        </Dialog>
    </>
}
