import { PropsWithoutRef, ReactElement } from 'react'
import { Icon } from '../../../../../../Theme/Components/Icon/Icon.tsx'
import { Tooltip } from 'primereact/tooltip'
import { classNames } from '../../../../../Shared/Infrastructure/React/classNames.ts'
import { CheckListTemplate } from '../../../Domain/CheckListTemplate.tsx'

export default function ItemCheckList({
  name,
  guid,
  global,
  onEdit,
  onRunTemplate,
  template,
}: PropsWithoutRef<{
  guid: string
  name: string
  global: boolean
  template: CheckListTemplate,
  onEdit: (guid: string) => void
  onRunTemplate: (guid: string) => void
}>): ReactElement {
  const isEditable = !global
  const onEditClickHandler = () => {
    if (!isEditable) return
    onEdit(guid)
  }
  const onRunClickHandler = () => {
    if (!template.amountItems()) return
    onRunTemplate(guid)
  }
  return (
    <div className='flex relative bg-gray-200 shadow-4 pr-2 pl-3' style={{ minHeight: 75 }}>
      <Tooltip target={'.tool-info'} mouseTrack />
      {global && (
        <div
          className='flex gap-1 mt-1 absolute text-gray-600 font-light'
          style={{ fontSize: '0.7rem' }}
        >
          <Icon name={'globalItem'} size={12} />
          <div>Global</div>
        </div>
      )}
      <div className='flex flex-grow-1 overflow-hidden'>
        <div
          className='tool-info align-self-center text-2xl white-space-nowrap text-overflow-ellipsis overflow-hidden'
          data-pr-tooltip={name}
        >
          {name}
        </div>
      </div>
      <div
        className='tool-info flex px-2 justify-content-center align-items-center border-round'
        data-pr-tooltip={'Edit Template'}
        onClick={onEditClickHandler}
      >
        <Icon
          name={'setting'}
          classname={classNames('flex',{ 'text-orange-500': isEditable, 'text-gray-500': !isEditable })}
          size={32}
        />
      </div>
      <div
        className={classNames('tool-info flex px-2 text-green-700 justify-content-center align-items-center border-round',{'text-gray-500': !template.amountItems()})}
        data-pr-tooltip={'Use Template'}
        onClick={onRunClickHandler}
      >
        <Icon name={'play'}classname="flex" size={32} />
      </div>
    </div>
  )
}
