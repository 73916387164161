import {CheckListTemplateItemDTO, CheckListTemplateItemPrimitives} from './CheckListTemplateItemDTO.tsx'
import {Entity} from "../../../Shared/Domain/Aggregate/Entity.ts";
import {Uuid} from "../../../Shared/Domain/ValueObject/Uuid.ts";

export interface CheckListItemCreateDTO {
    readonly guid: Uuid
    readonly text: string
    readonly sort: number
    readonly updatedAt: Date
}

export class CheckListTemplateItem extends Entity<CheckListTemplateItem, CheckListTemplateItemPrimitives> {
    readonly guid: Uuid
    readonly text: string
    readonly sort: number
    readonly updatedAt: Date

    constructor(data: CheckListTemplateItemDTO) {
        super()
        this.guid = data.guid
        this.text = data.text
        this.sort = data.sort
        this.updatedAt = data.updatedAt
    }

    static create(create: CheckListItemCreateDTO): CheckListTemplateItem {
        console.debug('createdNewTemplateItem', create)
        return new CheckListTemplateItem({guid: create.guid, sort: create.sort, text: create.text, updatedAt: create.updatedAt})
    }

    changeSort(sort: number): CheckListTemplateItem {
        if (sort === this.sort) return this
        return new CheckListTemplateItem({...this, sort, updatedAt: new Date()})
    }

    equals(other: CheckListTemplateItem): boolean {
        return other.guid === this.guid && other.updatedAt.getTime() === this.updatedAt.getTime()
    }

    static fromPrimitives(primitives: CheckListTemplateItemPrimitives): CheckListTemplateItem {
        return new CheckListTemplateItem({
            guid: new Uuid(primitives.guid),
            sort: primitives.sort,
            text: primitives.text,
            updatedAt: primitives.updatedAt
        })
    }

    toPrimitives(): CheckListTemplateItemPrimitives {
        return {guid: this.guid.value, sort: this.sort, text: this.text, updatedAt: this.updatedAt};
    }
}
