import {useEffect, useState} from 'react'

type ScreenBreakPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

export function useScreen() {
  const [x, setX] = useState(window.innerWidth)
  const [y, setY] = useState(window.innerHeight)

  const breakpoints: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number> = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400,
  }

  const screenUpTo = (breakpoint: Omit<ScreenBreakPoint, 'xxl'>): boolean => {
    switch (breakpoint) {
      case 'xs':
        return x > breakpoints.xs
      case 'sm':
        return x > breakpoints.sm
      case 'md':
        return x > breakpoints.md
      case 'lg':
        return x > breakpoints.lg
      case 'xl':
        return x > breakpoints.xl
      default:
        throw new Error(`Breakpoint ${breakpoint} not implemented.`)
    }
  }
  const screenDownTo = (breakpoint: Omit<ScreenBreakPoint, 'xs'>): boolean => {
    switch (breakpoint) {
      case 'sm':
        return x < breakpoints.xs
      case 'md':
        return x < breakpoints.sm
      case 'lg':
        return x < breakpoints.md
      case 'xl':
        return x < breakpoints.lg
      case 'xxl':
        return x < breakpoints.xl
      default:
        throw new Error(`Breakpoint ${breakpoint} not implemented.`)
    }
  }

  const currentBreakpoint= (():ScreenBreakPoint => {
    if (x < breakpoints.xs) return 'xs'
    if (x < breakpoints.sm) return 'sm'
    if (x < breakpoints.md) return 'md'
    if (x < breakpoints.lg) return 'lg'
    if (x < breakpoints.xl) return 'xl'
    return 'xxl'
  })()

  useEffect(() => {
    const updateSize = () => {
      setX(window.innerWidth)
      setY(window.innerHeight)
    }
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return { x, y, breakpoints, screenUpTo, screenDownTo, currentBreakpoint }
}
