import {PropsWithChildren, ReactElement} from 'react'
import UserMenuItem from "../User/UserMenuItem/UserMenuItem.tsx";

export default function NavSidebar({visible, width, fixed, onClose, children}: PropsWithChildren<{
    visible: boolean,
    width?: string | number,
    fixed?: boolean,
    onClose: () => void
}>): ReactElement {
    return (<>{!fixed && visible && <div className="absolute bg-black-alpha-20 w-full" style={{top: 60, bottom: 0}} onClick={onClose}/>}
        <div className="p-2 shadow-3 z-2 bg-white" style={{
            transform: !visible && !fixed ? 'translateX(-100%)' : undefined,
            transition: 'transform .3s cubic-bezier(0,0,.2,1)',
            position: 'fixed',
            width,
            top:60,
            left: 0,
            bottom: 0
        }}>
            <div className="pb-2">
                <UserMenuItem/>
            </div>
            {children}
        </div>
    </>)
}
