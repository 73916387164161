import {ReactElement, useContext, useEffect, useState} from 'react'
import PageLayout from '../../../../Theme/Components/Page/PageLayout.tsx'
import {useNavigate, useParams} from 'react-router-dom'
import TemplateItemForm from './Components/Form/TemplateItemForm.tsx'
import ItemCheckListItem from './Components/List/ItemCheckListItem.tsx'
import {Button} from 'primereact/button'
import {useCheckListTemplate} from '../Infrastructure/use/useCheckListTemplate.tsx'
import {CheckListTemplateItem} from '../Domain/CheckListTemplateItem.tsx'
import {CheckListTemplate} from '../Domain/CheckListTemplate.tsx'
import {AppContext} from "../../../../AppContext.tsx";

export default function CheckListTemplateItemsPage(): ReactElement {
    const {guid} = useParams<{ guid: string }>()
    const navigate = useNavigate()
    const {trans} = useContext(AppContext)
    const {createCheckListTemplate: {mutate}, getChecklistTemplate: {data, isSuccess}} = useCheckListTemplate({
        guid, onSuccess: () => {
            console.debug('mutation navigate')
            navigate('/checklist/template')
        }
    })
    const [template, setTemplate] = useState<CheckListTemplate>()
    useEffect(() => {
        if (!data) return
        setTemplate(data)
    }, [data])

    const onSaveHandler = () => {
        if (!template) return
        mutate(template)
    }
    // TODO: mejorar loading
    if (!template) return <div>'loading'</div>

    return (
        <PageLayout waitingData={isSuccess}>
            <div className='flex flex-column gap-3 h-full'>
                <h2 className=''>{template.name}</h2>
                <div>
                    <TemplateItemForm
                        onAdd={(item) => {
                            setTemplate(template.addItem(CheckListTemplateItem.create(item)))
                        }}
                    />
                </div>
                <div className='flex flex-column gap-2 flex-grow-1 overflow-y-auto'>
                    {template.items.map((item) => {
                        return (
                            <ItemCheckListItem
                                key={item.guid.value}
                                item={item}
                                onMove={(item, newPosition) => {
                                    setTemplate(template.moveItem(item.guid.value, newPosition))
                                }}
                                onRemove={(item) => {
                                    setTemplate(template.removeItem(item.guid.value))
                                }}
                            />
                        )
                    })}
                </div>
                <div className='flex justify-content-end'>
                    <Button label={trans.t('save')} onClick={onSaveHandler}/>
                </div>
            </div>
        </PageLayout>
    )
}
