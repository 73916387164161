import {Constructor, DomainEventSubscriber} from "../../../../Shared/Domain/Bus/DomainEventSubscriber.ts";
import {DomainEvent} from "../../../../Shared/Domain/Bus/DomainEvent.ts";
import {CheckListTemplateCreatedEvent} from "../../Domain/CheckListTemplateCreatedEvent.tsx";


export class CreateCheckListTemplateSubscriber implements DomainEventSubscriber<CheckListTemplateCreatedEvent> {
    eventPath: string = CheckListTemplateCreatedEvent.name;

    async on(domainEvent: CheckListTemplateCreatedEvent): Promise<void> {
        console.debug(CreateCheckListTemplateSubscriber.name, 'is executed with event', domainEvent, this)
    }

    subscribedTo(): Constructor<DomainEvent<any>>[] {
        return [CheckListTemplateCreatedEvent];
    }

}
