import {PrimeReactProvider} from 'primereact/api'
import {Router} from './Router/Router.tsx'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import AppProvider from "./AppContext.tsx";
import {CredentialsPrimitives} from "./Context/Account/Domain/CredentialsPrimitives.ts";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";

export interface AccountInfo {
    name?: string
    onlineMode: boolean
    credentials?: CredentialsPrimitives
    guid: string
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {networkMode: 'offlineFirst'},
        mutations: {networkMode: 'offlineFirst'}
    }
})

function App() {
    console.debug('APP LOAD')
    return (
        <PrimeReactProvider>
            <QueryClientProvider client={queryClient}>
                <AppProvider>
                    <Router/>
                    <ReactQueryDevtools initialIsOpen={true}/>
                </AppProvider>
            </QueryClientProvider>
        </PrimeReactProvider>
    )
}
export default App
