import {useContext} from "react";
import {AppContext} from "../../../AppContext.tsx";
import {DataSource} from "typeorm";
import {EventBus} from "../../Shared/Domain/Bus/EventBus.ts";
import {AccountEntity} from "../Infrastructure/Persistence/TypeOrm/AccountEntity.ts";
import {Account} from "../Domain/Account.ts";
import {Capacitor} from "@capacitor/core";
import {sqliteConnection} from "../../Shared/Infrastructure/Persistence/Sqlite/SqliteConnection.ts";
import {Uuid} from "../../Shared/Domain/ValueObject/Uuid.ts";
import {useQueryClient} from "@tanstack/react-query";
import {AccountGuidChangedEvent} from "../Domain/Events/AccountGuidChangedEvent.tsx";

export interface SignInThirdResponse {
    access_token: string,
    refresh_token: string
}

export const useAccountApplication = (options?: {
    db: DataSource, eventBus: EventBus
}) => {
    let db: DataSource;
    let eventBus: EventBus;
    const context = useContext(AppContext)
    if (options) {
        db = options.db
        eventBus = options.eventBus
    } else {
        db = context.db
        eventBus = context.eventBus
    }
    const query = useQueryClient()
    const database = db.options.database
    const accountRepository = db.getRepository(AccountEntity)

    const storeData = async () => {
        if (Capacitor.getPlatform() === 'web' && typeof database === 'string') {
            console.debug('[Account Application] Store WEB Data in DB', database)
            await sqliteConnection.saveToStore(database);
        }
    }

    const putAccount = async (account: Account) => {
        try {
            const primitives = account.toPrimitives()
            const accountEntity = new AccountEntity()
            accountEntity.guid = primitives.guid
            accountEntity.name = primitives.name || null
            accountEntity.onlineMode = primitives.onlineMode
            accountEntity.updatedAt = primitives.updatedAt
            accountEntity.createdAt = primitives.createdAt
            accountEntity.credentials = primitives.credentials
            await accountRepository.save(accountEntity)
            await storeData()
            await eventBus.publish(account.pullDomainEvents())
        } catch (e) {
            console.error(e)
        }
    }

    const findAppAccount = async () => {
        try {
            const user = await accountRepository.find()
            console.debug('users found:', user, !!user.length)
            if (!user.length) {
                const now = new Date()
                const newAccount: Account = Account.Create({
                    createdAt: now,
                    guid: Uuid.random(),
                    onlineMode: false,
                    updatedAt: now
                })
                await putAccount(newAccount)
                console.debug('Account created', newAccount)
                return newAccount
            }
            const accountUser = user[0]
            console.debug('Account received', accountUser)
            return Account.fromPrimitives({
                createdAt: accountUser.createdAt,
                guid: accountUser.guid,
                name: accountUser.name || undefined,
                onlineMode: accountUser.onlineMode,
                updatedAt: accountUser.updatedAt,
                credentials: accountUser.credentials
            })
        } catch (e) {
            console.error(e)
        }
    }
    const renameAccountGuid = async (guid: Uuid, oldGuid: Uuid) => {
        console.debug(`[Account Application] Change Guid of user`)
        await db.createQueryBuilder()
            .update('checklist_template')
            .set({createdBy: guid.value})
            .where({createdBy: oldGuid.value})
            .execute()
        await db.createQueryBuilder()
            .update('checklist')
            .set({createdBy: guid.value})
            .where({createdBy: oldGuid.value})
            .execute()
        await storeData()
    }
    const receiveCredentials = async (response: SignInThirdResponse) => {
        const account = await findAppAccount()
        if (!account) return
        const newAccount = account.receiveCredentials({
            access_token: response.access_token,
            refresh_token: response.refresh_token
        })
        await putAccount(newAccount)
        console.debug('putAccount:', newAccount)
        console.debug('Socket Test receiveCredentials')
        await storeData()
        // entry in mode online and server send a correct Guid for the account
        if (!account.guid.equals(newAccount.guid)) {
            console.debug(`[Account Application] different user guid detected`)
            await deleteAccount(account.guid.value)
            await context.updateAccount()
            await eventBus.publish([AccountGuidChangedEvent.create({newGuid: newAccount.guid.value, oldGuid: account.guid.value})])
            await query.invalidateQueries()
            return
        }
        await context.updateAccount()
    }

    const signOut = async () => {
        const guidAccount = context.account.guid
        await accountRepository.createQueryBuilder().delete().execute()
        await storeData()
        const res1 = await db.createQueryBuilder()
            .delete()
            .from('checklist_template')
            .where({createdBy: guidAccount})
            .execute()
        await storeData()
        const res2 = await db.createQueryBuilder()
            .delete()
            .from('checklist')
            .where({createdBy: guidAccount})
            .execute()
        await storeData()
        console.debug(`[Account Application] remove all from user "${guidAccount}", checklist removed (${res2.affected}), checklist_template removed (${res1.affected})`)
        await context.updateAccount()
        await query.invalidateQueries()
    }

    const deleteAccount = async (guid: string) => {
        await accountRepository.delete({guid})
        await storeData()
    }

    return {findAppAccount, receiveCredentials, signOut, putAccount, deleteAccount, renameAccountGuid}
}
