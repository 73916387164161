import {useContext, useState} from 'react'
import { Dialog } from 'primereact/dialog'
import { CheckListTask } from '../../Domain/CheckListTask.tsx'
import { shortDateTimeFormat } from '../../../../Shared/Infrastructure/Date/format.ts'
import {AppContext} from "../../../../../AppContext.tsx";

export default function useCheckListTaskInfoDialog() {
  const {trans}= useContext(AppContext)
  const [visible, setVisible] = useState(false)
  const [task, setTask] = useState<CheckListTask>()
  const dialog = (
    <Dialog
      header={trans.t('Task info')}
      visible={visible}
      onHide={() => {
        setVisible(false)
      }}
    >
      <div className='flex gap-3'>
        <div className='flex flex-column gap-2'>
          <div className="text-right">{trans.t('Text')}:</div>
          <div className="text-right">{trans.t('Performed At')}:</div>
        </div>
        <div className='flex flex-column gap-2'>
          <div className='text-center'>
            <strong>{task?.text}</strong>
          </div>
          <div className='flex-grow-1 text-center'>
            <strong>{shortDateTimeFormat(task?.performedAt) || '--'}</strong>
          </div>
        </div>
      </div>
    </Dialog>
  )
  const openTaskInfoDialog = (task: CheckListTask) => {
    setTask(task)
    setVisible(true)
  }
  return { taskInfoDialog: dialog, openTaskInfoDialog }
}
