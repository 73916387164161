import {Capacitor} from "@capacitor/core";
import {sqliteConnection} from "../../../Shared/Infrastructure/Persistence/Sqlite/SqliteConnection.ts";
import {CheckList} from "../Domain/CheckList.tsx";
import {useContext} from "react";
import {CheckListEntity} from "../Infrastructure/persistence/TypeOrm/CheckList.Entity.ts";
import {AppContext} from "../../../../AppContext.tsx";
import {Uuid} from "../../../Shared/Domain/ValueObject/Uuid.ts";

export const useCheckListApplication = () => {
    const {eventBus, db, api} = useContext(AppContext)
    const database = db.options.database
    const checkListRepository = db.getRepository(CheckListEntity)

    const storeData = async () => {
        if (Capacitor.getPlatform() === 'web' && typeof database === 'string') {
            console.debug('[CheckListApplication] Store WEB Data in DB')
            await sqliteConnection.saveToStore(database);
        }
    }

    const mapToCheckList = (entity: CheckListEntity): CheckList => {
        return CheckList.fromPrimitives({
            archivedAt: entity.archivedAt || undefined,
            completedAt: entity.completedAt || undefined,
            createdAt: entity.createdAt,
            createdBy: entity.createdBy,
            dueAt: entity.dueAt || undefined,
            guid: entity.guid,
            name: entity.name,
            tasks: entity.tasks,
            templateGuid: entity.templateGuid || undefined,
            updatedAt: entity.updatedAt,
        })
    }

    const putCheckList = async (checklist: CheckList, skipHttp = false) => {
        try {
            console.debug('[CheckListApplication] PutCheckList', checklist, skipHttp)
            const primitives = checklist.toPrimitives()
            const checkListEntity = new CheckListEntity()
            checkListEntity.guid = primitives.guid
            checkListEntity.name = primitives.name
            checkListEntity.templateGuid = primitives.templateGuid || null
            checkListEntity.tasks = primitives.tasks
            checkListEntity.dueAt = primitives.dueAt || null
            checkListEntity.completedAt = primitives.completedAt || null
            checkListEntity.archivedAt = primitives.archivedAt || null
            checkListEntity.createdAt = primitives.createdAt
            checkListEntity.createdBy = primitives.createdBy
            checkListEntity.updatedAt = primitives.updatedAt
            if (!skipHttp) {
                for (const event of checklist.pullSourceEvents()) {
                    try {
                        await api.put({
                            route: `/checkList/${event.aggregateId}/apply`, body: event
                        })
                    }catch (e) {
                        console.debug('[CheckListApplication] PutCheckList http error', e)
                    }
                }
            }
            await checkListRepository.save(checkListEntity)
            await storeData()
            await eventBus.publish(checklist.pullDomainEvents())
            console.debug('[CheckListApplication] PutCheckList Finished')
        } catch (e) {
            console.error(e)
        }
    }

    const findAllCheckList = async () => {
        try {
            const checklistEntities = await checkListRepository.find()
            console.debug('[CheckListApplication] findAllCheckList', checklistEntities)
            return checklistEntities.map(mapToCheckList)
        } catch (e) {
            console.error(e)
            return [] as CheckList[]
        }
    }

    const findByIdCheckList = async (guid: string) => {
        try {
            const result = await checkListRepository.findOneBy({guid})
            if (!result) return
            return mapToCheckList(result)
        } catch (e) {
            console.error(e)
        }
    }

    const removeCheckList = async (checkList: CheckList) => {
        try {
            if (!await findByIdCheckList(checkList.guid.value)) return
            await checkListRepository.delete({guid: checkList.guid.value})
            await storeData()
            await eventBus.publish(checkList.pullDomainEvents())
            const event = {
                aggregateId: checkList.guid.value,
                aggregateName: "CheckList",
                content: {},
                eventId: Uuid.random().value,
                eventName: 'Delete',
                occurredOn: new Date()
            }
            await api.put({
                route: `/checkList/${event.aggregateId}/apply`, body: event
            })
        } catch (e) {
            console.error(e)
        }
    }

    const clear = async ()=> {
        await checkListRepository.delete({})
        await storeData()
    }

    return {
        putCheckList, findByIdCheckList, findAllCheckList, removeCheckList, clear
    }
}
