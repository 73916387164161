import {PropsWithoutRef, ReactElement} from 'react'
import {CheckListTask} from '../../Domain/CheckListTask.tsx'
import {Icon} from '../../../../../Theme/Components/Icon/Icon.tsx'
import {classNames} from '../../../../Shared/Infrastructure/React/classNames.ts'
import InputTextInline from "../../../../Shared/Infrastructure/React/Components/Form/TextInline/InputTextInline.tsx";

export default function CheckListTaskItem({
                                              task,
                                              onToggleCheck,
                                              onInfoClick,
                                              onChangeText
                                          }: PropsWithoutRef<{
    task: CheckListTask
    onToggleCheck: (task: CheckListTask) => void
    onInfoClick: (task: CheckListTask) => void,
    onChangeText: (task: CheckListTask, text:string) => void
}>): ReactElement {
    const onCheckToggleHandler = () => {
        onToggleCheck(task)
    }
    const onInfoClickHandler = () => {
        onInfoClick(task)
    }
    return (
        <div
            className='flex gap-2 align-items-center bg-gray-300 border-round p-2'
            style={{minHeight: 60}}
        >
            <div className="cursor-pointer" onClick={onCheckToggleHandler}>
                <Icon
                    classname='flex'
                    size={'2rem'}
                    name={task.performedAt ? 'squareChecked' : 'squareUnchecked'}
                />
            </div>
            <InputTextInline disabled={!!task.performedAt} className={classNames({'line-through': !!task.performedAt})} value={task.text} onChange={(text) => {
                onChangeText(task, text)
            }}/>
            <div onClick={onInfoClickHandler}>
                <Icon size={'1.8rem'} classname="flex text-blue-400" name={'arrowLeft'}/>
            </div>
        </div>
    )
}
