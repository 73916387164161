import {MigrationInterface, QueryRunner, Table} from "typeorm"

export class CheckList1699621913368 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.createTable(new Table({
            name: 'checklist',
            columns: [
                {name: 'guid', type: 'varchar', isPrimary: true},
                {name: 'name', type: 'varchar'},
                {name: 'template_guid', type: 'varchar', isNullable: true},
                {name: 'tasks', type: 'text'},
                {name: 'due_at', type: 'integer', isNullable: true},
                {name: 'completed_at', type: 'integer', isNullable: true},
                {name: 'archived_at', type: 'integer', isNullable: true},
                {name: 'created_at', type: 'integer'},
                {name: 'created_by', type: 'varchar'},
                {name: 'updated_at', type: 'integer'}
            ]
        }))
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.dropTable("checklist")
    }

}
