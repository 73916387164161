import {PropsWithoutRef, ReactElement, useMemo, useRef} from 'react'
import {Icon} from "../../../../../../../Theme/Components/Icon/Icon.tsx";
import {Menu} from "primereact/menu";
import {MenuItem} from "primereact/menuitem";
import {MenuItemType} from "../MenuItemType.tsx";
import {MenuItemParent} from "../MenuItemParent.tsx";
import {MenuItemAction} from "../MenuItemAction.tsx";

interface ButtonMenuProps {
    items: (MenuItemAction | MenuItemParent)[]
}

export default function ButtonMenu({items}: PropsWithoutRef<ButtonMenuProps>): ReactElement {
    const menuRef = useRef<Menu>(null);
    const mappedItems = useMemo(() => {
        return mapItems(items)
    }, [items])
    return (<>
        <div
            className="border-gray-100 border-1 py-2 px-1 border-round shadow-1 cursor-pointer hover:bg-gray-50 hover:shadow-none"
            onClick={(e) => menuRef.current?.toggle(e)}>
            <Icon classname="flex" name={'menuDot'}/>
        </div>
        <Menu popup popupAlignment="right" ref={menuRef} model={mappedItems} pt={{action:{className:'gap-2'}}}/>
    </>)
}

const mapItems = (items: MenuItemType[]): MenuItem[] => {
    const mapped:MenuItem[] = []
    for (const item of items) {
        const i:Partial<MenuItem> = {label: item.label, icon: item.icon}
        if (item['action']) {
            const iAction = item as MenuItemAction
            if (typeof iAction.action === 'string') i['url'] = iAction.action as string
            if (typeof iAction.action === 'function') i['command'] = iAction.action
        }
        mapped.push(i)
    }
    return mapped
}
