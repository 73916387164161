import {ReactElement} from 'react'
import {createBrowserRouter, NavLink, RouteObject, RouterProvider} from 'react-router-dom'
import Layout from '../Theme/Layout/Layout.tsx'
import CheckListTemplatePage from '../Context/ChekList/Template/Pages/CheckListTemplatePage.tsx'
import CheckListTemplateItemsPage from '../Context/ChekList/Template/Pages/CheckListTemplateItemsPage.tsx'
import ListCheckListPage from '../Context/ChekList/Execution/Pages/ListCheckListPage.tsx'
import CheckListPage from '../Context/ChekList/Execution/Pages/CheckListPage.tsx'
import PageNotFound from "../Context/Shared/Infrastructure/React/Page/PageNotFound.tsx";
import PageError from "../Context/Shared/Infrastructure/React/Page/PageError.tsx";
import PageLayout from "../Theme/Components/Page/PageLayout.tsx";

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    errorElement: <PageError/>,
    children: [
      {
        path: '',
        errorElement: <PageError/>,
        element: <PageLayout><div>
          <div>Home</div>
          <div className="flex gap-3">
            <div>
              <NavLink to={'/checklist'}>CheckList</NavLink>
            </div>
            <div>
              <NavLink to={'/checklist/template'}>CheckList template</NavLink>
            </div>
          </div>
        </div></PageLayout>,
      },
      {
        path: 'checklist/template',
        element: <CheckListTemplatePage />,
        children:[
          {
            path: ':guid',
            element: <CheckListTemplateItemsPage />,
          },
        ]
      },

      {
        path: 'checklist',
        element: <ListCheckListPage />,
        children: [
          {
            path: ':guid',
            element: <CheckListPage />,
          },
        ]
      },
      {
        path: '*',
        element: <PageNotFound/>,
      },
    ],
  },
]

const router = createBrowserRouter(routes)

export function Router(): ReactElement {
  console.debug('ROUTER LOAD')
  return <RouterProvider router={router} />
}
