import { Dialog } from 'primereact/dialog'
import {PropsWithoutRef, ReactElement, useContext} from 'react'
import TemplateForm from '../Form/TemplateForm.tsx'
import {AppContext} from "../../../../../../AppContext.tsx";

export default function TemplateDialog({
  guid,
  visible,
  onSuccess,
}: PropsWithoutRef<{
  visible: boolean
  onSuccess: (guid?:string) => void
  guid?: string
}>): ReactElement {
    const {trans} = useContext(AppContext)
  return (
    <Dialog
      header={trans.t('Header')}
      visible={visible}
      onHide={() => {
        console.debug('onHide CheckListTemplate Form')
        onSuccess()
      }}
    >
      <TemplateForm
        guid={guid}
        onSuccess={(guid) => {
          console.debug('onSuccess CheckListTemplate Form')
          onSuccess(guid)
        }}
      />
    </Dialog>
  )
}
