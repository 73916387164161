import {PropsWithoutRef, ReactElement, useContext} from 'react'
import {Calendar} from "primereact/calendar";
import {AppContext} from "../../../../../../../AppContext.tsx";

interface FormDateTimeProps {
    date: Date | undefined
    onChange: (date?: Date | null) => void
    inline?: boolean
    placeholder?:string
}

export default function FormCalendar({date, onChange, inline, placeholder}: PropsWithoutRef<FormDateTimeProps>): ReactElement {
    const {screen: {screenDownTo}} = useContext(AppContext)
    return <Calendar placeholder={placeholder} style={{minWidth: screenDownTo('md') ? 320 : 430}} value={date} onChange={(e) => onChange(e.value)}
                     inline={inline} showButtonBar/>
}
