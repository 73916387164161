import {CSSProperties, useContext, useEffect, useState} from "react";
import NavSidebar from "./NavSidebar.tsx";
import {AppContext} from "../../../../../../AppContext.tsx";
import MobileMenu from "./MobileMenu.tsx";
import {Icon} from "../../../../../../Theme/Components/Icon/Icon.tsx";
import NavMenu from "./NavMenu.tsx";
import MobileSidebar from "./MobileSidebar.tsx";

export const useNavUI = () => {
    const [sideBar, setSideBar] = useState(false)
    const {screen: {screenDownTo, screenUpTo}} = useContext(AppContext)
    const isMobile = screenDownTo('sm')
    const isBigger = screenUpTo('xl')
    const closeSidebar = () => setSideBar(false)
    useEffect(() => {
        closeSidebar()
    }, [isBigger, isMobile]);
    const marginLeft = '17rem'
    const btnMenu = !isMobile && !isBigger &&
        <div className="px-2 cursor-pointer" onClick={() => setSideBar(old => !old)}>
            <Icon name={'menu'} classname="flex font-bold" size={24}/>
        </div>
    const styleBody = () => {
        const styleBodyContent: CSSProperties = {}
        styleBodyContent.transition = 'margin-left .3s cubic-bezier(0,0,.2,1)'
        if (isBigger) styleBodyContent.marginLeft = marginLeft
        return styleBodyContent
    }
    const navMenu = <NavMenu onNavigate={closeSidebar}/>
    const navSidebar = isMobile ?
        <MobileSidebar children={navMenu} visible={sideBar} onClose={closeSidebar}/> :
        <NavSidebar visible={sideBar} width={marginLeft} fixed={isBigger} onClose={closeSidebar}
                    children={navMenu}/>
    const mobileMenu = isMobile && <MobileMenu onMenu={()=> setSideBar(true)}/>

    return {btnMenu, styleBody, navSidebar, mobileMenu}
}
