import {ReactElement, useContext} from 'react'
import PageLayout from "../../../../../Theme/Components/Page/PageLayout.tsx";
import {NavLink} from "react-router-dom";
import {AppContext} from "../../../../../AppContext.tsx";

export default function PageNotFound(): ReactElement {
    const {trans} = useContext(AppContext)
    return (<PageLayout>
        <div className="flex flex-grow-1 flex-column align-items-center">
            <div className="pt-8">
                <div className="text-8xl font-bold">404</div>
                <div className="text-xl">{trans.t('Page not found.')}</div>
                <div className="pt-2">{trans.t('Go to')} <NavLink to="/">{trans.t('home')}</NavLink>.</div>
            </div>
        </div>
    </PageLayout>)
}
