import {MigrationInterface, QueryRunner, Table} from "typeorm"

export class AccountOffline1699798596146 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.createTable(new Table({
            name: 'account', columns: [
                {
                    type: 'varchar',
                    name: 'guid',
                    isPrimary: true
                },
                {
                    type: 'varchar',
                    name: 'online_mode'
                },
                {
                    type: 'varchar',
                    name: 'name',
                    isNullable: true,
                },
                {
                    type: 'integer',
                    name: 'created_at'
                },
                {
                    type: 'integer',
                    name: 'updated_at'
                }
            ]
        }))
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.dropTable('account')
    }

}
