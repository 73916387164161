import { DateTime } from 'luxon'

export const shortDateTimeFormat = (date?: Date): string | undefined => {
  if (!date) return
  return DateTime.fromJSDate(date).toFormat('dd/M/yy HH:mm')
}

export const shortDateFormat = (date?: Date): string | undefined => {
  if (!date) return
  return DateTime.fromJSDate(date).toFormat('dd/M/yy')
}
