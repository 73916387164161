import {
    ApiResponse,
    ApiSendOptions,
    HttpApiCredentials,
    HttpApiRepository
} from "../../../Domain/Persistence/HttpApiRepository.ts";

export class FetchHttpApiRepository extends HttpApiRepository {

    constructor(config: {
        loadCredentials: () => Promise<HttpApiCredentials>,
        storeCredentials: (response: ApiResponse) => Promise<HttpApiCredentials>
    }) {
        super(config);
    }
    protected async refreshAccessToken(): Promise<HttpApiCredentials> {
        const credentials = await this.loadCredentials()
        console.debug('RefreshToken', credentials)
        if (!credentials) throw new Error('Forbidden Refresh Access Token')
        const response = await this.post({
            route: 'auth/token',
            authenticated: false,
            body: {
                refresh_token: credentials.refreshToken,
                grant_type: 'refresh_token',
                user_guid: credentials.userGuid
            }
        });
        return this.storeCredentials(response)
    }

    protected defaultAuthenticated: boolean = true;

    protected async send(options: ApiSendOptions): Promise<ApiResponse> {

            console.debug('Send Api Req', options)
            const authRequired = options.authenticated=== undefined? this.defaultAuthenticated: options.authenticated
            if (authRequired) {
                options.headers = {...options.headers, ...await this.header()}
                console.debug('Adding the Auth Header', options.headers)
            }
            const res = await fetch(this.formUrl(options.route, options.query), {
                body: JSON.stringify(options.body),
                method: options.method,
                headers: {
                    ...{
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    ...options.headers,
                }
            })
            if (res.ok) {

                return {data: await res.json()}
            }
            return {data: undefined}

    }

    private formUrl(route: string, query?: URLSearchParams): URL {
        const url = new URL(process.env.REACT_APP_API_SERVER_HOST)
        url.pathname = url.pathname.concat(route)
        console.debug(`[${FetchHttpApiRepository.name}] form url ${url.toString()}`)
        if (query) url.search = query.toString()
        return url
    }
}
