import {PropsWithoutRef, ReactElement, useContext} from 'react'
import {Icon} from "../../../../../../Theme/Components/Icon/Icon.tsx";
import TreeMenu from "../Menu/TreeMenu/TreeMenu.tsx";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../../../../AppContext.tsx";
import {capitalizeAllString} from "../../../String/StringCase.ts";

export default function NavMenu({onNavigate}:PropsWithoutRef<{ onNavigate:()=>void }>): ReactElement {
    const navigate = useNavigate()
    const {trans:{t}} = useContext(AppContext)
    return (<TreeMenu onActionSuccessful={onNavigate} items={[
        {
            label: capitalizeAllString(t('home')),
            action() {
                navigate('/')
            },
            icon: <Icon classname="flex" name={'home'} size={24}/>
            // className: 'sticky z-1 bg-white',
            // style: {top: 0, background: 'white'}
        },
        {
            label: capitalizeAllString(t('checklist')), items:
                [
                    {
                        label: capitalizeAllString(t('execution')),
                        icon: <Icon classname="flex" name={'checklist'} size={24}/>,
                        action() {
                            navigate('/checklist')
                        }
                    },
                    {
                        label: capitalizeAllString(t('template')),
                        icon: <Icon classname="flex" name={'checklistTemplate'} size={24}/>,
                        action() {
                            navigate('/checklist/template')
                        },
                    }
                ]
        }
    ]}/>)
}
