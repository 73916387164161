import {useEffect, useState} from "react";

export function useOffline(){
    const [offline, setOffline] = useState(false)
    useEffect(() => {
        const onHandlerOffline = (state:boolean) => {
            return () => {
                setOffline(state)
            }
        }

        window.addEventListener('offline', onHandlerOffline(true))
        window.addEventListener('online', onHandlerOffline(false))
        return () => {
            window.removeEventListener('offline', onHandlerOffline(true))
            window.removeEventListener('online', onHandlerOffline(false))
        }
    }, []);
    return {offline}
}
