import {DomainEvent, DomainEventPrimitive} from "../../../Shared/Domain/Bus/DomainEvent.ts";

export interface AccountGuidChangedEventPrimitives {
    readonly newGuid: string
    readonly oldGuid: string
}

export class AccountGuidChangedEvent extends DomainEvent<AccountGuidChangedEventPrimitives> {
    static EVENT_NAME = 'AccountGuidChanged'

    static create(data: AccountGuidChangedEventPrimitives): AccountGuidChangedEvent {
        return new AccountGuidChangedEvent(AccountGuidChangedEvent.EVENT_NAME, data.newGuid, data)
    }

    static fromPrimitives(primitives: DomainEventPrimitive<AccountGuidChangedEventPrimitives>): AccountGuidChangedEvent {
        return new AccountGuidChangedEvent(AccountGuidChangedEvent.EVENT_NAME, primitives.aggregateId, primitives.content, primitives.eventId, primitives.occurredOn)
    }

    toPrimitive(): DomainEventPrimitive<AccountGuidChangedEventPrimitives> {
        return {
            aggregateId: this.aggregateId,
            content: this.content,
            eventId: this.eventId,
            eventName: this.eventName,
            occurredOn: this.occurredOn
        };
    }
}
