import {PropsWithChildren, ReactElement, TouchEventHandler, useState} from 'react'
import style from './SwipeRow.module.scss'
import {classNames} from "../../classNames.ts";

interface SwipeRowProps {
    className?: string
    onLeftAction?: () => void
    leftIcon?: ReactElement
    lefBackground?: string
    onRightAction?: () => void
    rightIcon?: ReactElement
    rightBackground?: string
}

export default function SwipeRow({
                                     children,
                                     lefBackground = '#9b9b9b',
                                     rightBackground = '#10b210',
                                     onRightAction,
                                     rightIcon,
                                     onLeftAction,
                                     leftIcon,
                                     className = 'border-round h-4rem bg-gray-100'
                                 }: PropsWithChildren<SwipeRowProps>): ReactElement {
    const asideWidth = 60
    const [moveDelta, setMoveDelta] = useState(0)
    const [initialMove, setInitialMove] = useState<number | undefined>(undefined)
    const [moveDirection, setMoveDirection] = useState<'l' | 'r' | undefined>()
    const handlerMovement = (xMove: number) => {
        const rightAside = asideWidth * -1
        const leftAside = asideWidth
        let newXPosition = (xMove * 0.5) + moveDelta
        const direction = moveDirection ? moveDirection : newXPosition < 0 ? 'l' : newXPosition > 0 ? 'r' : undefined
        if (direction !== moveDirection) setMoveDirection(direction)
        if (moveDelta === rightAside && newXPosition <= rightAside) return
        if (direction === 'l') {
            if (!onRightAction) return;
            if (newXPosition < rightAside) newXPosition = rightAside
            if (newXPosition > 0) newXPosition = 0
        } else if (direction === 'r') {
            if (!onLeftAction) return;
            if (newXPosition > leftAside) newXPosition = leftAside
            if (newXPosition < 0) newXPosition = 0
        }
        setMoveDelta(() => newXPosition)
    }

    const resetMovement = () => {
        if (moveDelta <= asideWidth * -1) onRightAction?.call(null)
        if (moveDelta >= asideWidth) onLeftAction?.call(null)
        setMoveDelta(0)
        setMoveDirection(undefined)
        setInitialMove(undefined)
    }

    const handlerStartTouch: TouchEventHandler = (e) => {
        if (e.touches[0]) setInitialMove(e.touches[0].clientX)
    }

    const handlerTouchMove: TouchEventHandler = (e) => {
        e.stopPropagation()
        const currentPosition = e.touches[0].clientX
        const xMove = currentPosition - initialMove!
        handlerMovement(xMove)
        setInitialMove(() => currentPosition)
    }

    return (<div
        className={classNames(style.SwipeRowContainer, "flex overflow-x-hidden", className)}>
        <main className="relative w-full h-fit" style={{left: moveDelta}} onMouseMoveCapture={(e) => {
            if (e.buttons === 1) handlerMovement(e.movementX)
        }} onTouchMove={handlerTouchMove} onMouseUp={resetMovement} onTouchStart={handlerStartTouch}
              onTouchCancel={resetMovement} onTouchEnd={resetMovement} onMouseLeave={resetMovement}>
            <aside className="flex absolute h-full justify-content-center align-items-center"
                   style={{width: asideWidth, background: lefBackground}}>
                {leftIcon}
            </aside>
            {children}
            <aside className="flex absolute h-full justify-content-center align-items-center"
                   style={{width: asideWidth, background: rightBackground}}>
                {rightIcon}
            </aside>
        </main>
    </div>)
}
