import {CheckListTemplate} from '../../Template/Domain/CheckListTemplate.tsx'
import {CheckListDTO} from './CheckListDTO.tsx'
import {CheckListTask} from './CheckListTask.tsx'
import {AggregateRoot} from "../../../Shared/Domain/Aggregate/AggregateRoot.ts";
import {Uuid} from "../../../Shared/Domain/ValueObject/Uuid.ts";
import {CheckListPrimitives} from "./CheckListPrimitives.tsx";

export class CheckList extends AggregateRoot<CheckList, CheckListPrimitives> {
    readonly guid: Uuid
    readonly name: string
    readonly templateGuid?: string
    readonly tasks: CheckListTask[]
    readonly dueAt?: Date
    readonly completedAt?: Date
    readonly archivedAt?: Date
    readonly updatedAt: Date
    readonly createdAt: Date
    readonly createdBy: string

    constructor(data: CheckListDTO & { tasks: CheckListTask[] }) {
        super()
        this.guid = data.guid
        this.name = data.name
        this.templateGuid = data.templateGuid
        this.tasks = data.tasks
        this.dueAt = data.dueAt
        this.completedAt = data.completedAt
        this.archivedAt = data.archivedAt
        this.updatedAt = data.updatedAt
        this.createdAt = data.createdAt
        this.createdBy = data.createdBy
    }

    static create(newData: { name: string, createdBy: string, dueAt?: Date }): CheckList {
        const now = new Date()

        const checklist = new CheckList({
            archivedAt: undefined,
            completedAt: undefined,
            createdAt: now,
            createdBy: newData.createdBy,
            dueAt: newData.dueAt,
            guid: Uuid.random(),
            name: newData.name,
            tasks: [],
            templateGuid: undefined,
            updatedAt: now
        })

        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            content: checklist.toPrimitives(),
            eventId: Uuid.random().value,
            eventName: "Create",
            occurredOn: now,
            aggregateName: 'CheckList'
        })
        return checklist
    }

    static recreateFromOffline(oldCheckList:CheckList, newGuid:string): CheckList {
        const checklist = new CheckList({
            archivedAt: oldCheckList.archivedAt,
            completedAt: oldCheckList.completedAt,
            createdAt: oldCheckList.createdAt,
            createdBy: newGuid?newGuid:oldCheckList.createdBy,
            dueAt: oldCheckList.dueAt,
            guid: oldCheckList.guid,
            name: oldCheckList.name,
            tasks: oldCheckList.tasks,
            templateGuid: oldCheckList.templateGuid,
            updatedAt: oldCheckList.updatedAt
        })

        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            content: checklist.toPrimitives(),
            eventId: Uuid.random().value,
            eventName: "Create",
            occurredOn: checklist.createdAt,
            aggregateName: 'CheckList'
        })
        return checklist
    }

    static createFromTemplate(name: string, creator: string, template: CheckListTemplate): CheckList {
        const tasks = template.items.map((item) => {
            return new CheckListTask({
                guid: Uuid.random(),
                text: item.text,
                sort: item.sort,
                itemGuid: item.guid.value,
            })
        })
        const now = new Date()
        const checklist = new CheckList({
            archivedAt: undefined,
            completedAt: undefined,
            createdAt: now,
            createdBy: creator,
            dueAt: undefined,
            guid: Uuid.random(),
            name,
            tasks: tasks,
            templateGuid: template.guid.value,
            updatedAt: now,
        })
        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            aggregateName: "CheckList",
            content: checklist.toPrimitives(),
            eventId: Uuid.random().value,
            eventName: 'Create',
            occurredOn: now
        })
        return checklist
    }

    complete(): CheckList {
        if (this.completedAt) return this
        const now = new Date()
        const tasksModified = this.tasks.map((t) => {
            if (!t.performedAt) return new CheckListTask({...t, performedAt: now})
            return t
        })
        const newData = {
            ...this,
            completedAt: now,
            tasks: tasksModified,
            updatedAt: now,
        }
        const checklist =new CheckList(newData)
        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            aggregateName: "CheckList",
            content: {completeAt: now},
            eventId: Uuid.random().value,
            eventName: "Complete",
            occurredOn: now
        })
        return checklist
    }

    unComplete(): CheckList {
        if (!this.completedAt) return this
        const undoDate = this.completedAt
        const tasksModified = this.tasks.map((t) => {
            if (t.performedAt?.getTime() === undoDate.getTime()) return new CheckListTask({
                ...t,
                performedAt: undefined
            })
            return t
        })
        const newData = {
            ...this,
            completedAt: undefined,
            tasks: tasksModified,
            updatedAt: new Date(),
        }
        const checklist =new CheckList(newData)
        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            aggregateName: "CheckList",
            content: {},
            eventId: Uuid.random().value,
            eventName: "UnComplete",
            occurredOn: new Date()
        })
        return checklist
    }

    checkTask(task: CheckListTask): CheckList {
        console.debug('checking task 0', task)
        if (this.isArchived) return this
        console.debug('checking task 1', task)
        const currentTask = this.tasks.find((t) => t.guid.equals(task.guid))
        console.debug('checking task 2', currentTask)
        if (!currentTask || !!currentTask.performedAt) return this
        console.debug('checking task 3', task)
        const now = new Date()
        const tasksModified = this.tasks.map((t) => {
            if (t.guid.equals(task.guid)) return new CheckListTask({...t, performedAt: now})
            return t
        })
        console.debug('checking task 4', tasksModified)
        const newData = {
            ...this,
            completedAt: this.isAllTaskCompleted(tasksModified) ? now : undefined,
            tasks: tasksModified,
            updatedAt: now,
        }
        const checklist =new CheckList(newData)
        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            aggregateName: "CheckList",
            content: {taskGuid: task.guid.value},
            eventId: Uuid.random().value,
            eventName: "CheckTask",
            occurredOn: now
        })
        return checklist
    }

    changeNameTask(task: CheckListTask, name: string): CheckList {
        if (this.isArchived) return this
        const currentTask = this.tasks.find((t) => t.guid.equals(task.guid))
        if (!currentTask) return this
        const tasksModified = this.tasks.map((t) => {
            if (t.guid.equals(task.guid)) return new CheckListTask({...t, text: name})
            return t
        })
        const newData = {
            ...this,
            tasks: tasksModified,
            updatedAt: new Date(),
        }
        const checklist =new CheckList(newData)
        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            aggregateName: "CheckList",
            content: {taskGuid: task.guid.value, name: name},
            eventId: Uuid.random().value,
            eventName: "RenameTask",
            occurredOn: new Date()
        })
        return checklist
    }

    addNewTask(task: CheckListTask): CheckList {
        if (this.isArchived || !!this.completedAt) return this
        const newData = {
            ...this,
            tasks: [...this.tasks, task],
            updatedAt: new Date(),
        }
        const checklist =new CheckList(newData)
        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            aggregateName: "CheckList",
            content: task.toPrimitives(),
            eventId: Uuid.random().value,
            eventName: "AddTask",
            occurredOn: new Date()
        })
        return checklist
    }

    unCheckTask(task: CheckListTask): CheckList {
        if (this.isArchived) return this
        const currentTask = this.tasks.find((t) => t.guid.equals(task.guid))
        if (!currentTask || !currentTask.performedAt) return this
        const now = new Date()
        const tasksModified = this.tasks.map((t) => {
            if (t.guid.equals(task.guid)) return new CheckListTask({...t, performedAt: undefined})
            return t
        })
        const newData = {
            ...this,
            completedAt: this.isAllTaskCompleted(tasksModified) ? now : undefined,
            tasks: tasksModified,
            updatedAt: now,
        }
        const checklist =new CheckList(newData)
        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            aggregateName: "CheckList",
            content: {taskGuid: task.guid.value},
            eventId: Uuid.random().value,
            eventName: "UnCheckTask",
            occurredOn: now
        })
        return checklist
    }

    get isArchived(): boolean {
        return !!this.archivedAt
    }

    get isDue(): boolean {
        if (!this.dueAt || !!this.completedAt) return false
        return this.dueAt.getTime() < Date.now()
    }

    get isCompleted():boolean{
        return !!this.completedAt
    }

    changeDueDate(duaAt?: Date): CheckList {
        if (this.isArchived) return this
        console.debug('pre changing due date', duaAt?.getTime() === this.dueAt?.getTime(), duaAt?.getTime(), this.dueAt?.getTime())
        if (duaAt?.getTime() === this.dueAt?.getTime()) return this
        console.debug('changing due date')
        const checklist =new CheckList({...this, dueAt: duaAt, updatedAt: new Date()})
        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            aggregateName: "CheckList",
            content: {expireAt: duaAt},
            eventId: Uuid.random().value,
            eventName: "ChangeExpire",
            occurredOn: new Date()
        })
        return checklist
    }

    archive(): CheckList {
        if (this.isArchived) return this
        const now = new Date()
        const checklist =new CheckList({...this, archivedAt: now, updatedAt: now})
        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            aggregateName: "CheckList",
            content: {archiveAt: now},
            eventId: Uuid.random().value,
            eventName: "Archive",
            occurredOn: now
        })
        return checklist
    }

    unArchive(): CheckList {
        if (!this.isArchived) return this
        const now = new Date()
        const checklist =new CheckList({...this, archivedAt: undefined, updatedAt: now})
        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            aggregateName: "CheckList",
            content: {},
            eventId: Uuid.random().value,
            eventName: "UnArchive",
            occurredOn: now
        })
        return checklist
    }

    private isAllTaskCompleted(tasks: CheckListTask[]): boolean {
        return tasks.every((task) => task.performedAt !== undefined)
    }

    changeName(name: string): CheckList {
        if (!name) return this
        const checklist = new CheckList({...this, name, updatedAt: new Date()})
        checklist.sourceEvents.push({
            aggregateId: checklist.guid.value,
            aggregateName: "CheckList",
            content: {name},
            eventId: Uuid.random().value,
            eventName: "Rename",
            occurredOn: new Date()
        })
        return checklist
    }

    sort(other: CheckList): number {
        const compareName = ()=> {
            if (this.name < other.name) return -1
            if (this.name > other.name) return 1
            return 0
        }
        if (this.dueAt && !this.isCompleted && other.dueAt && !other.isCompleted){
            const thisDueAt = this.dueAt.getTime()
            const otherDueAt = other.dueAt.getTime()
            const sortDate = thisDueAt - otherDueAt
            if (sortDate!==0)return sortDate
            return compareName()
        }
        if (this.dueAt && !this.isCompleted) return -1
        if (other.dueAt && !other.isCompleted) return 1
        return compareName()
    }

    toPrimitives(): CheckListPrimitives {
        return {
            archivedAt: this.archivedAt,
            completedAt: this.completedAt,
            createdAt: this.createdAt,
            createdBy: this.createdBy,
            dueAt: this.dueAt,
            guid: this.guid.value,
            name: this.name,
            tasks: this.tasks.map(t => t.toPrimitives()),
            templateGuid: this.templateGuid,
            updatedAt: this.updatedAt
        };
    }

    static fromPrimitives(primitives: CheckListPrimitives): CheckList {
        return new CheckList({
            archivedAt: primitives.archivedAt,
            completedAt: primitives.completedAt,
            createdAt: primitives.createdAt,
            createdBy: primitives.createdBy,
            dueAt: primitives.dueAt,
            guid: new Uuid(primitives.guid),
            name: primitives.name,
            tasks: primitives.tasks.map(t => CheckListTask.fromPrimitives(t)),
            templateGuid: primitives.templateGuid,
            updatedAt: primitives.updatedAt,
        })
    }
}
