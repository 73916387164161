import {PropsWithRef, ReactElement, useEffect, useState} from 'react'
import {classNames} from "../../../classNames.ts";
import {Icon} from "../../../../../../../Theme/Components/Icon/Icon.tsx";
import {useNavigate} from "react-router-dom";
import {MenuItemParent} from "../MenuItemParent.tsx";
import {MenuItemAction} from "../MenuItemAction.tsx";
import {MenuItemType} from "../MenuItemType.tsx";


export default function TreeMenu({items, onActionSuccessful}: PropsWithRef<{
    items: Array<MenuItemType>,
    onActionSuccessful: () => void
}>): ReactElement {
    return (<div className="overflow-y-auto">
        <ul className="list-none m-0">
            {items.map((item, idx) => {
                return <MenuItem item={item} key={idx} onActionSuccessful={onActionSuccessful}
                                 onChildren={() => undefined}/>
            })}
        </ul>
    </div>)
}

export function MenuItem({item, collapsed = true, onChildren, onActionSuccessful}: PropsWithRef<{
    item: MenuItemParent | MenuItemAction,
    collapsed?: boolean,
    onActionSuccessful: () => void,
    onChildren: (nChildrens: number) => void
}>): ReactElement {
    let cItem
    if (item['items']) {
        cItem = item as MenuItemParent
    } else if (item['action']) {
        cItem = item as MenuItemAction
    } else {
        cItem = item
    }
    const items: Array<MenuItemType> | undefined = cItem.items
    const [openedChildrens, setOpenedChildrens] = useState(0)
    const [childrenCollapsed, setChildrenCollapsed] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (collapsed && !childrenCollapsed) {
            setChildrenCollapsed(true)
            setOpenedChildrens(0)
        }
    }, [collapsed]);

    useEffect(() => {
        onChildren(openedChildrens)
    }, [openedChildrens]);
    const toggleExpand = () => {
        setChildrenCollapsed(old => {
            const newStatus = !old
            const nItems = items?.length || 0
            setOpenedChildrens(!newStatus ? nItems : 0)
            return !old
        })
    }

    const actionHandler = () => {
        if (!cItem.action) return undefined
        if (typeof cItem.action === 'string') {
            navigate(cItem.action)
        } else {
            cItem.action.call()
        }
        onActionSuccessful()
    }

    return (<li className={classNames("w-full overflow-y-hidden pb-2")}>
        <a className="flex flex-grow-1 p-2 px-3 align-content-center border-round bg-gray-100 hover:bg-black-alpha-10 w-full">
            <span className="mr-2" style={{minWidth: '1.5rem'}}>{item.icon}</span>
            <span className={classNames("flex", {'flex-grow-1': !items?.length, 'cursor-pointer': !!cItem.action})}
                  onClick={actionHandler}>{item.label}</span>
            {!!items && <span className="flex flex-grow-1 cursor-pointer justify-content-end align-items-center mr-1"
                              onClick={() => toggleExpand()}>
                <Icon name={'caret'}
                      classname={classNames("flex text-right transition-transform transition-duration-500", {'rotate-180': !childrenCollapsed})}
                      size={19}/>
            </span>}
        </a>
        {!!items &&
            <ul className={classNames("transition-all transition-duration-500 transition-ease-in-out list-none overflow-y-hidden pl-3 pt-2 m-0")}
                style={{maxHeight: openedChildrens * 51}}>
                {items.map((item, idx) => {
                    return <MenuItem item={item} key={idx} collapsed={childrenCollapsed}
                                     onActionSuccessful={onActionSuccessful}
                                     onChildren={(n) => setOpenedChildrens(old => old + n)}/>
                })}
            </ul>}
    </li>)
}
