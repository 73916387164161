import {PropsWithChildren, ReactElement} from 'react'
import {Icon} from '../Icon/Icon.tsx'
import {useNavigate} from 'react-router-dom'

export default function PageLayout({
                                       children,
                                       waitingData = true,
                                       returnNavigate
                                   }: PropsWithChildren<{
    waitingData?: unknown | undefined
    returnNavigate?: string
}>): ReactElement {
    const navigate = useNavigate()
    const someTool = !!returnNavigate
    return (
        <div className='flex flex-grow-1 overflow-auto flex-column w-full lg:max-w-30rem'>
            {someTool && (
                <div className='sm:hidden p-2'>
                    {!!returnNavigate &&
                        <div onClick={() => {
                            navigate(returnNavigate)
                        }}>
                            <Icon size={'2rem'} name={'arrowLeft'}/>
                        </div>
                    }
                </div>
            )}
            <div className='flex flex-column flex-grow-1 p-2 w-full overflow-auto lg:max-w-30rem'>{!!waitingData && children}</div>
        </div>
    )
}
