import {useContext, useState} from "react";
import {Dialog} from "primereact/dialog";
import FormCalendar from "../../../../Shared/Infrastructure/React/Components/Form/Date/FormCalendar.tsx";
import {Button} from "primereact/button";
import {CheckList} from "../../Domain/CheckList.tsx";
import {useCheckListQueries} from "../../Infrastructure/use/useCheckListQueries.tsx";
import {AppContext} from "../../../../../AppContext.tsx";
import {capitalizeAllString} from "../../../../Shared/Infrastructure/String/StringCase.ts";

export const useFormExpireAt = ({checklist}: { checklist: CheckList }) => {
    const [visible, setVisible] = useState(false)
    const {trans: {t}, account, api} = useContext(AppContext)
    const [expireDate, setExpireDate] = useState<Date | undefined>(undefined)
    const {putCheckList: {mutate}} = useCheckListQueries({account, api})

    const openForm = () => {
        setVisible(true)
        setExpireDate(checklist.dueAt)
    }

    const cancelForm = () => {
        setVisible(false)
        setExpireDate(undefined)
    }

    const confirmForm = (reset?: boolean) => {
        const newChecklist = checklist.changeDueDate(reset ? undefined : expireDate)
        mutate(newChecklist)
        setVisible(false)
    }

    const dialog = <Dialog onHide={() => setVisible(false)} visible={visible}
                           header={capitalizeAllString(t('change expire date'))}>
        <div className="flex justify-content-center">
            <FormCalendar date={expireDate} onChange={(date) => setExpireDate(date ?? undefined)} inline/>
        </div>
        <div className="flex justify-content-between pt-4">
            <Button severity={'danger'} outlined onClick={cancelForm}>{capitalizeAllString(t('cancel'))}</Button>
            <Button severity={'success'} onClick={() => confirmForm()}>{capitalizeAllString(t('save'))}</Button>
        </div>
    </Dialog>

    return {dialog, openForm}
}
