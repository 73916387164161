import {MigrationInterface, QueryRunner, TableColumn} from "typeorm"

export class AccountWithCredentials1702152267181 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.addColumn('account', new TableColumn({name: 'credentials', type: 'text', isNullable: true}))
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.dropColumn('account', 'credentials')
    }

}
