import {ValueTransformer} from "typeorm";

export const jsonTransform = <T>():ValueTransformer=> {
    return {
        from(value: string): T {
            const parsed = JSON.parse(value, (key, value) => {
                console.debug('Parsing Json', key, value)
                if (key.toLowerCase().endsWith('at')) {
                    return new Date(value)
                }
                return value
            }) as T
            console.debug('Parsed Json', parsed)
            return parsed
        }, to(value: T): string {
            return JSON.stringify(value)
        }
    }
}
