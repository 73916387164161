import {ReactElement, useContext} from 'react'
import {Button} from 'primereact/button'
import {useCreateCheckListTemplate} from '../../../Infrastructure/use/useCreateCheckListTemplate.tsx'
import {AppContext} from "../../../../../../AppContext.tsx";

export default function NewCheckListTemplateButton(): ReactElement {
    const {trans} = useContext(AppContext)
    const {CreateCheckListTemplateForm, openForm} = useCreateCheckListTemplate()
    const onClickHandler = () => {
        openForm()
    }
    return (
        <>
            <Button size='small' severity='success' raised onClick={onClickHandler}>
                {trans.t('New Template')}
            </Button>
            {CreateCheckListTemplateForm}
        </>
    )
}
