import {Column, Entity, PrimaryColumn} from "typeorm";
import {CheckListTemplateItemPrimitives} from "../../../Domain/CheckListTemplateItemDTO.tsx";
import {jsonTransform} from "../../../../../Shared/Infrastructure/Persistence/TypeOrm/JsonTransform.ts";
import {booleanTransform} from "../../../../../Shared/Infrastructure/Persistence/TypeOrm/BooleanTransform.ts";
import {dateTransform} from "../../../../../Shared/Infrastructure/Persistence/TypeOrm/DateTransform.ts";

@Entity('checklist_template')
export class CheckListTemplateEntity {
    @PrimaryColumn({type: 'varchar'})
    guid!: string
    @Column({type: 'varchar'})
    name!: string
    @Column({type: 'varchar', transformer: booleanTransform})
    global!: boolean
    @Column({type: 'integer', name: 'created_at', transformer: dateTransform})
    createdAt!: Date
    @Column({type: 'varchar', name: 'created_by'})
    createdBy!: string
    @Column({type: 'integer', name: 'updated_at', transformer: dateTransform})
    updatedAt!: Date
    @Column({type: 'text', transformer: jsonTransform<CheckListTemplateItemPrimitives[]>()})
    items!: CheckListTemplateItemPrimitives[]
}
