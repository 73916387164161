import {PropsWithoutRef, ReactElement} from 'react'
import {Icon} from "../../../../../../Theme/Components/Icon/Icon.tsx";
import {classNames} from "../../../../../Shared/Infrastructure/React/classNames.ts";

export default function CheckByStatus({state, toggle, isExpired}: PropsWithoutRef<{
    toggle: () => void,
    state: boolean,
    isExpired?:boolean
}>): ReactElement {
    const iconName = state ? 'squareChecked' : 'squareUnchecked'
    return (<div onClick={() => toggle()}><Icon size={32} classname={classNames('flex',{'text-red-600': isExpired, 'text-green-600': state})} name={iconName}/></div>)
}
