import {PropsWithoutRef, ReactElement} from 'react'
import {CheckList} from '../../Domain/CheckList.tsx'
import {Icon} from '../../../../../Theme/Components/Icon/Icon.tsx'
import {useNavigate} from 'react-router-dom'
import SwipeRow from "../../../../Shared/Infrastructure/React/Components/SwipeRow/SwipeRow.tsx";
import CheckByStatus from "./Check/CheckByStatus.tsx";
import {shortDateFormat} from "../../../../Shared/Infrastructure/Date/format.ts";
import {classNames} from "../../../../Shared/Infrastructure/React/classNames.ts";

export default function ListCheckListItem({
                                              item,
                                              onRemove,
                                              onCheckToggle
                                          }: PropsWithoutRef<{
    item: CheckList
    onRemove: (item: CheckList) => void
    onCheckToggle: (item: CheckList) => void
}>): ReactElement {
    const navigate = useNavigate()
    const onDeleteClickHandler = () => {
        onRemove(item)
    }
    const onSelectClickHandler = () => {
        navigate(`/checklist/${item.guid}`)
    }

    return (
        <div>
            <SwipeRow
                onRightAction={onDeleteClickHandler}
                className="border-round border-2 border-primary-200 bg-primary-50"
                rightIcon={<Icon name={'trash'} size={32} classname='flex text-white'/>}
                rightBackground={'#bb0d0d'}
            >
                <div className="flex p-1 flex-column justify-content-center">
                    <div className="flex align-items-center">
                        <div className="flex flex-column align-items-center">
                            <CheckByStatus toggle={() => onCheckToggle(item)} state={!!item.completedAt} isExpired={item.isDue}/>
                        </div>
                        <div className="flex flex-column px-2 flex-grow-1">
                            <div className={'flex flex-grow-1'}>{item.name}</div>
                            <div className="text-xs">
                                {!!item.tasks.length && <small className={'pr-1'}>{item.tasks.filter(i=>!!i.performedAt).length} / {item.tasks.length}</small>} {!!item.dueAt && <small className={classNames({'text-red-600': item.isDue})}>{shortDateFormat(item.dueAt)}</small>}
                            </div>
                        </div>
                        <div className="cursor-pointer" onClick={onSelectClickHandler}><Icon
                            classname="flex p-2 text-primary" name={'caretRight'} size={32}/></div>
                    </div>
                </div>
            </SwipeRow>
        </div>
    )
}
