import {ReactElement, useContext, useEffect, useState} from 'react'
import {useCheckListQueries} from '../../Infrastructure/use/useCheckListQueries.tsx'
import {CheckList} from '../../Domain/CheckList.tsx'
import ListCheckListItem from './ListCheckListItem.tsx'
import EmptyListAdvisor from "../../../Share/Infrastructure/Components/EmptyList/EmptyListAdvisor.tsx";
import {AppContext} from "../../../../../AppContext.tsx";
import {Button} from "primereact/button";
import {useFormNewCheckList} from "./useFormNewCheckList.tsx";

export default function ListCheckList(): ReactElement {
    const [list, setList] = useState<CheckList[]>()
    const {trans, refToast, account, api} = useContext(AppContext)
    const {
        getListChecklist: {data, dataUpdatedAt},
        putCheckList: {mutate: putMutate},
        deleteCheckList: {mutate: deleteMutate},
    } = useCheckListQueries({account, api})
    useEffect(() => {
        if (!data) return setList([])
        setList(data)
    }, [data, dataUpdatedAt])

    const {dialog: newChecklistDialog, openForm:openNewChecklistDialog} = useFormNewCheckList()
    return (
        <div className='flex flex-column gap-2 flex-grow-1 overflow-y-hidden'>
            {!list?.length && <EmptyListAdvisor message={trans.t('No CheckLists')}/>}
            <div className="flex">
                <Button label={'new checklist'} onClick={()=> openNewChecklistDialog()} className="w-full text-center"/>
            </div>
            {newChecklistDialog}
            <div className="flex flex-column gap-2 overflow-y-auto">
                {list?.sort((a,b)=> a.sort(b)).map((i) => {
                    return (
                        <ListCheckListItem
                            key={i.guid.value}
                            item={i}
                            onCheckToggle={(checklist)=> {
                                const newChecklist = checklist.completedAt?checklist.unComplete():checklist.complete()
                                putMutate(newChecklist)
                            }}
                            onRemove={(checkList) => {
                                refToast?.current?.show({
                                    severity: 'error', sticky:true, content: (
                                        <div className="flex flex-column align-items-center">
                                            <div className="text-center">
                                                <i className="pi pi-exclamation-triangle"></i>
                                                <div className="font-bold text-xl my-3">Are you sure?</div>
                                            </div>
                                            <div className="flex gap-2">
                                                <Button size={'small'} severity="danger" outlined label="Cancel"
                                                        onClick={() => refToast.current?.clear()}/>
                                                {!checkList.isArchived && <Button severity="info" label="Archive" onClick={() => {
                                                    putMutate(checkList.archive())
                                                    refToast.current?.clear()
                                                }}/>}
                                                <Button size={'small'} severity="danger" label="Delete" onClick={() => {
                                                    deleteMutate(checkList)
                                                    refToast.current?.clear()
                                                }}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }}
                        />
                    )
                })}
            </div>
        </div>
    )
}
