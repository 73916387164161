export default {
    offline: 'Offline',
    "Task info": "Task Info",
    "Text": "Text",
    "Performed At": "Performed At",
    "Completed": "Completed",
    "Tasks": "Tasks",
    "New Template": "New Template",
    "Add": "Add",
    "Header": "Header",
    "New CheckList": "New CheckList",
    "Create": "Create",
    "save": "Save",
    "Sorry, but something are not working correctly, try to reload the app.": "Sorry, but something are not working correctly, try to reload the app.",
    "Go to": "Go to",
    "home": "home",
    "template": "template",
    "checklist": "checklist",
    "execution": "execution",
    "Page not found.": "Page not found.",
    "No Templates": "No Templates",
    "No CheckLists": "No CheckLists"
}
