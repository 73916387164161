export interface ApiSendOptions {
    route: string,
    query?: URLSearchParams
    method: 'POST' | 'GET' | 'DELETE' | 'PUT'
    body?: any
    headers: Record<string, string>
    authenticated?: boolean
}

export interface ApiResponse<Data = any> {
    data: Data
}

export interface GetApiOptions {
    route: string
    query?: URLSearchParams
    authenticated?: boolean
}

export interface PutApiOptions<Body = any> extends GetApiOptions {
    body: Body
}

export type PostApiOptions = PutApiOptions

export type DeleteApiOptions = GetApiOptions

export interface HttpApiCredentials {
    readonly accessToken: string
    readonly expireAt: Date
    readonly refreshToken: string
    readonly userGuid:string
}

export interface AuthHeader {
    authorization: string
}

export abstract class HttpApiRepository {
    protected abstract defaultAuthenticated: boolean
    protected loadCredentials: () => Promise<HttpApiCredentials>
    protected storeCredentials: (response: ApiResponse) => Promise<HttpApiCredentials>

    protected abstract send(options: ApiSendOptions): Promise<ApiResponse>

    protected abstract refreshAccessToken(): Promise<HttpApiCredentials>

    protected constructor(config: {
        loadCredentials: () => Promise<HttpApiCredentials>,
        storeCredentials: (response: ApiResponse) => Promise<HttpApiCredentials>
    }) {
        this.loadCredentials = config.loadCredentials
        this.storeCredentials = config.storeCredentials
    }

    protected async header(): Promise<AuthHeader> {
        let credentials = await this.loadCredentials()
        console.debug('Current Credentials', credentials)
        console.debug('Get Header - isExpired', credentials.expireAt.getTime() <= Date.now(), credentials.expireAt, new Date())
        if (credentials.expireAt.getTime() <= Date.now()) credentials = await this.refreshAccessToken()
        return {authorization: `Bearer ${credentials.accessToken}`}
    }

    public get<Data = any>(options: GetApiOptions): Promise<ApiResponse<Data>> {
        return this.send({
            authenticated: options.authenticated,
            body: undefined,
            headers: {},
            method: 'GET',
            query: options.query,
            route: options.route
        })
    }

    public put<Data = any>(options: PutApiOptions): Promise<ApiResponse<Data>> {
        return this.send({
            authenticated: options.authenticated,
            body: options.body,
            headers: {},
            method: 'PUT',
            query: options.query,
            route: options.route
        })
    }

    public post<Data = any>(options: PostApiOptions): Promise<ApiResponse<Data>> {
        return this.send({
            authenticated: options.authenticated,
            body: options.body,
            headers: {},
            method: 'POST',
            query: options.query,
            route: options.route
        })
    }

    public delete<Data = any>(options: DeleteApiOptions): Promise<ApiResponse<Data>> {
        return this.send({
            authenticated: options.authenticated,
            body: undefined,
            headers: {},
            method: 'DELETE',
            query: options.query,
            route: options.route
        })
    }
}
