import i18next, {InitOptions} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import es from './es'
import en from './en'

const devConfig = (active?: boolean): Partial<InitOptions> => {
    if (!active || process.env.NODE_ENV !== 'development') return {debug: process.env.NODE_ENV === 'development'}
    return {
        saveMissing: active,
        missingKeyNoValueFallbackToKey: active,
        saveMissingTo: 'all',
        supportedLngs: ['fr'],
        missingKeyHandler: (lngs, ns, key) => {
            const missingLangString = localStorage.getItem('missing_lang') || "{}"
            const langMissing = JSON.parse(missingLangString)
            for (const lngsKey of lngs) {
                if (!langMissing[lngsKey]) langMissing[lngsKey] = {}
                if (!langMissing[lngsKey][ns]) langMissing[lngsKey][ns] = {}
                langMissing[lngsKey][ns][key] = 'missing'
            }
            localStorage.setItem('missing_lang', JSON.stringify(langMissing))
            return
        },
        debug: active,
        fallbackLng: 'fr'
    }
}

await i18next.use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        ns: ['general'],
        defaultNS: 'general',
        resources: {en: en, es: es},
        ...devConfig(),

    });

export {i18next}
