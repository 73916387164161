import {DataSource, LoggerOptions} from 'typeorm';
import {sqliteConnection} from "../Sqlite/SqliteConnection";

let dataSource:DataSource

export const AppDataSource = (entities: Function[], migrations?:Function[]) => {
    const arrayMigrations = migrations || []
    if (dataSource) return dataSource
    const log:LoggerOptions = process.env.NODE_ENV === 'development' ? ['error', 'query', 'schema']: ['error']
    dataSource = new DataSource({
        type: 'capacitor',
        driver: sqliteConnection,
        database: 'app-database',
        entities: entities,
        migrations: arrayMigrations,
        logging: log,
        synchronize: false,
        migrationsRun: false,
    });
    return dataSource
}
