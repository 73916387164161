import {ValueTransformer} from "typeorm";

export const dateTransform: ValueTransformer = {
    from(value?: number): Date | undefined {
        if (!value) return
        return new Date(value)
    }, to(value?: Date): number | null {
        if (!value) return null
        return value.getTime()
    }
}
