import {ReactElement, useContext} from 'react'
import {AppContext} from "../../../../../../../AppContext.tsx";
import {Icon} from "../../../../../../../Theme/Components/Icon/Icon.tsx";
import {GoogleAuth} from "../Login/useUserLogin.tsx";
import {useAccountApplication} from "../../../../../../Account/Application/useAccountApplication.tsx";
import {useQueryClient} from "@tanstack/react-query";

export default function UserMenuItem(): ReactElement {
    const {account} = useContext(AppContext)
    const queryClient = useQueryClient()
    const {signOut} = useAccountApplication()
    return (<div className="flex flex-column p-2 gap-2">
        <div className="flex align-items-center gap-2">
            <Icon classname="flex" name={'user'} size={32}/>
            <div className="flex-grow-1 text-overflow-ellipsis">{account.name||'anonymous'}</div>
            <Icon classname="flex text-red-500 cursor-pointer" onClick={() =>{
                signOut().then(async () => {
                    await queryClient.invalidateQueries()
                })
            }} name={'signOut'} size={24}/>
        </div>
        {!account.credentials && <GoogleAuth/>}
    </div>)
}
