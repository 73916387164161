import {CheckListTemplateItem} from './CheckListTemplateItem.tsx'
import {CheckListTemplateDTO, CheckListTemplatePrimitives} from './CheckListTemplateDTO.tsx'
import {AggregateRoot} from "../../../Shared/Domain/Aggregate/AggregateRoot.ts";
import {Uuid} from "../../../Shared/Domain/ValueObject/Uuid.ts";
import {CheckListTemplateCreatedEvent} from "./CheckListTemplateCreatedEvent.tsx";


export interface CheckListTemplateCreate {
    readonly guid: Uuid
    readonly name: string
    readonly global: boolean
    readonly createdAt: Date
    readonly createdBy: string
    readonly updatedAt: Date
}

export class CheckListTemplate extends AggregateRoot<CheckListTemplate, CheckListTemplatePrimitives> {
    readonly items: CheckListTemplateItem[]
    readonly guid: Uuid
    readonly name: string
    readonly createdAt: Date
    readonly createdBy: string
    readonly updatedAt: Date
    readonly global: boolean

    constructor(data: Omit<CheckListTemplateDTO, 'items'> & { items: CheckListTemplateItem[] }) {
        super()
        this.guid = data.guid
        this.name = data.name
        this.createdAt = data.createdAt
        this.createdBy = data.createdBy
        this.global = data.global
        this.updatedAt = data.updatedAt
        this.items = data.items
    }

    sort(template: CheckListTemplate): number {
        if (this.global === template.global) {
            if (this.name === template.name) return 0
            if (this.name > template.name) return 1
            if (this.name < template.name) return -1
        }
        return !this.global ? 1 : -1
    }

    static create(
        data: CheckListTemplateCreate
    ): CheckListTemplate {
        const template = new CheckListTemplate({
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            createdBy: data.createdBy,
            global: data.global,
            guid: data.guid,
            name: data.name,
            items: [],
        })
        const event = CheckListTemplateCreatedEvent.create({
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            createdBy: data.createdBy,
            global: data.global,
            guid: data.guid.value,
            name: data.name,
        })
        template.record(event)
        return template
    }

    moveItem(itemGuid: string, newPosition: number): CheckListTemplate {
        const item = this.items.find(i => i.guid.value === itemGuid)
        if (!item) throw new Error('Item to move not found')
        const items = this.items.filter((item) => item.guid.value !== itemGuid)
        const newPositionValidate = this.validNewPosition(newPosition, items.length)
        items.splice(newPositionValidate, 0, item)
        return new CheckListTemplate({...this, items: this.updatedPositions(items)})
    }

    removeItem(itemGuid: string): CheckListTemplate {
        const item = this.items.find(i => i.guid.value === itemGuid)
        if (!item) throw new Error('Item to move not found')
        const items = this.items.filter((item) => item.guid.value !== itemGuid)
        return new CheckListTemplate({...this, items: this.updatedPositions(items)})
    }

    addItem(itemToAdd: CheckListTemplateItem): CheckListTemplate {
        this.items.push(itemToAdd)
        console.debug('add item to template', itemToAdd, this.items)
        return new CheckListTemplate({...this, items: this.updatedPositions(this.items)})
    }

    amountItems(): number {
        return this.items.length
    }

    protected validNewPosition(newPosition: number, totalPosition: number): number {
        if (newPosition < 0) return 0
        if (newPosition > totalPosition) return totalPosition
        return newPosition
    }

    protected updatedPositions(items: CheckListTemplateItem[]): CheckListTemplateItem[] {
        return items.map((item, idx) => item.changeSort(idx))
    }

    static fromPrimitives(primitives: CheckListTemplatePrimitives): CheckListTemplate {
        return new CheckListTemplate({
            ...primitives,
            items: primitives.items.map(i => CheckListTemplateItem.fromPrimitives(i)),
            guid: new Uuid(primitives.guid)
        })
    }

    toPrimitives(): CheckListTemplatePrimitives {
        return {
            createdAt: this.createdAt,
            createdBy: this.createdBy,
            global:
            this.global,
            guid: this.guid.value,
            items: this.items.map(i => i.toPrimitives()),
            name: this.name,
            updatedAt: this.updatedAt
        };
    }
}
