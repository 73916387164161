import {ReactElement, useContext, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {Toast} from 'primereact/toast'
import {AppContext} from "../../AppContext.tsx";
import OfflineInfo from "../Components/OfflineIndicator/OfflineInfo.tsx";
import AppLogo from "../../Context/Shared/Infrastructure/React/Components/Logo/AppLogo.tsx";
import {useNavUI} from "../../Context/Shared/Infrastructure/React/Components/NavMenu/useNavUI.tsx";
import {useRealtimeBus} from "../../Context/Shared/Infrastructure/React/Realtime/useRealtimeBus.tsx";
import {ChangeCheckListSubscriber} from "../../Context/ChekList/Execution/Application/ChangeCheckListSubscriber.ts";
import {useCheckListApplication} from "../../Context/ChekList/Execution/Application/useCheckListApplication.ts";


export default function Layout(): ReactElement {
    const {refToast, account, eventBus} = useContext(AppContext)
    const{btnMenu, styleBody, navSidebar, mobileMenu} = useNavUI()
    const checkListApplication = useCheckListApplication()
    console.debug('LAYOUT LOAD')
    useRealtimeBus({account})
    useEffect(() => {
        eventBus.addSubscribers([new ChangeCheckListSubscriber(checkListApplication)])
    }, [eventBus]);
    return (
        <div className="flex flex-column h-full">
            <div className="flex align-items-center bg-primary px-3" style={{minHeight: 60}}>
                <div className="flex align-content-center align-items-center text-2xl">
                    <AppLogo/>
                    <div className="pl-2">Litow</div>
                    {btnMenu}
                </div>
                <OfflineInfo/>
            </div>
            <div className="flex flex-grow-1 overflow-hidden justify-content-center" style={{...styleBody()}} >
                {navSidebar}
                <Outlet/>
            </div>
            {mobileMenu}
            <Toast ref={refToast}/>
        </div>
    )
}
