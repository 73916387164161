import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {CheckListTemplate} from '../../Domain/CheckListTemplate.tsx'
import {useCheckListTemplateApplications} from "../../Application/useCheckListTemplateApplications.ts";

type onSuccessFn = (guid: string) => void

export function useCheckListTemplate(options?: { guid?: string, onSuccess?: onSuccessFn }) {
    const queryClient = useQueryClient()
    const {putChecklistTemplate, findAllCheckListTemplate, findByIdCheckListTemplate} = useCheckListTemplateApplications()

    const createCheckListTemplate = useMutation({
        mutationFn: async (item: CheckListTemplate) => {
            return  putChecklistTemplate(item)
        },
        onMutate: async (putTemplate: CheckListTemplate) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            console.debug('onMutate CheckListTemplate', putTemplate)
            await queryClient.cancelQueries({queryKey: ['CheckListTemplate']})

            // Snapshot the previous value
            const previousCheckListTemplates = queryClient.getQueryData(['CheckListTemplate'])
            console.debug('onMutate previousCheckListTemplates', previousCheckListTemplates)

            // Optimistically update to the new value
            queryClient.setQueryData(
                ['CheckListTemplate'],
                (oldTemplates: CheckListTemplate[]) => {
                    if (!oldTemplates) return []
                    if (!oldTemplates.some((t) => t.guid.equals(putTemplate.guid))) {
                        oldTemplates.push(putTemplate)
                    } else {
                        for (const item of oldTemplates) {
                            if (!item.guid.equals(putTemplate.guid)) continue
                            Object.assign(item, putTemplate)
                        }
                    }
                    console.debug('onMutate Store optimistically', oldTemplates)
                    return [...oldTemplates]
                },
            )

            // Return a context object with the snapshotted value
            return {previousTodos: previousCheckListTemplates}
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (_err, _newTodo, context) => {
            console.debug('onError ListCheckListTemplate', context?.previousTodos)
            queryClient.setQueryData(['CheckListTemplate'], context?.previousTodos)
        },
        // Always refetch after error or success:
        onSettled: (_data, error, template) => {
            void queryClient.invalidateQueries({queryKey: ['CheckListTemplate']})
            console.debug('onSettled ListCheckListTemplate', template, error)
            if (!error) {
                console.debug('mutation call onSuccess')
                options?.onSuccess?.call(null, template.guid.value)
            }
        },
    })

    const getChecklistTemplate = useQuery({
        queryKey: ['CheckListTemplate', options?.guid],
        queryFn: () => findByIdCheckListTemplate(options!.guid!),
        enabled: !!options?.guid
    })

    const getListChecklistTemplates = useQuery({
        queryKey: ['CheckListTemplate'],
        queryFn: () => findAllCheckListTemplate()
    })

    return {
        createCheckListTemplate,
        getChecklistTemplate,
        getListChecklistTemplates,
    }
}
