import {useContext, useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {CheckList} from "../../Domain/CheckList.tsx";
import {useCheckListQueries} from "../../Infrastructure/use/useCheckListQueries.tsx";
import {AppContext} from "../../../../../AppContext.tsx";
import {capitalizeAllString} from "../../../../Shared/Infrastructure/String/StringCase.ts";
import {InputText} from "primereact/inputtext";
import FormCalendar from "../../../../Shared/Infrastructure/React/Components/Form/Date/FormCalendar.tsx";

export const useFormNewCheckList = () => {
    const [visible, setVisible] = useState(false)
    const {trans: {t}, account, api} = useContext(AppContext)
    const [name, setName] = useState<string>('')
    const [due, setDue] = useState<Date | undefined>(undefined)
    const {putCheckList: {mutate}} = useCheckListQueries({account, api})
    const openForm = () => {
        setVisible(true)
        setName('')
        setDue(undefined)
    }

    const cancelForm = () => {
        setVisible(false)
    }

    const confirmForm = () => {
        const newChecklist = CheckList.create({
            createdBy: account.guid, dueAt: due, name: name
        })
        mutate(newChecklist)
        setVisible(false)
    }

    const dialog = <Dialog onHide={() => setVisible(false)} visible={visible}
                           header={capitalizeAllString(t('new checklist'))}>
        <div className="flex flex-column gap-2 justify-content-center">
            <InputText value={name} placeholder={'name'} required onChange={(e) => setName(e.target.value)}/>
            <FormCalendar date={due} placeholder={'expire at'} onChange={(date) => setDue(date || undefined)}/>
        </div>
        <div className="flex justify-content-between pt-4">
            <Button severity={'danger'} outlined onClick={cancelForm}>{capitalizeAllString(t('cancel'))}</Button>
            <Button severity={'success'} onClick={confirmForm}>{capitalizeAllString(t('save'))}</Button>
        </div>
    </Dialog>


    return {dialog, openForm}
}
