import {AggregateRoot} from "../../Shared/Domain/Aggregate/AggregateRoot.ts";
import {Uuid} from "../../Shared/Domain/ValueObject/Uuid.ts";
import {AccountDTO} from "./AccountDTO.ts";
import {AccountPrimitives} from "./AccountPrimitives.ts";
import {CredentialsPrimitives} from "./CredentialsPrimitives.ts";

interface AccessTokenClaim {
    exp: Date
    email: string,
    sub: string,
    givenName?: string
    username?: string
}

export class Account extends AggregateRoot<Account, AccountPrimitives> {
    readonly guid: Uuid
    readonly name?: string
    readonly onlineMode: boolean
    readonly credentials?: CredentialsPrimitives
    readonly updatedAt: Date
    readonly createdAt: Date

    constructor(dto: AccountDTO) {
        super();
        this.guid = dto.guid
        this.name = dto.name
        this.onlineMode = dto.onlineMode
        this.updatedAt = dto.updatedAt
        this.createdAt = dto.createdAt
        this.credentials = dto.credentials
    }

    toPrimitives(): AccountPrimitives {
        return {
            createdAt: this.createdAt,
            guid: this.guid.value,
            name: this.name,
            credentials: this.credentials,
            onlineMode: this.onlineMode,
            updatedAt: this.updatedAt
        };
    }

    static fromPrimitives(primitives: AccountPrimitives): Account {
        console.debug('Account From Primitives', primitives)
        return new Account({
            createdAt: primitives.createdAt,
            guid: new Uuid(primitives.guid),
            name: primitives.name,
            credentials: primitives.credentials,
            onlineMode: primitives.onlineMode,
            updatedAt: primitives.updatedAt
        })
    }

    static Create(dto: AccountDTO): Account {
        return new Account({
            guid: dto.guid,
            name: dto.name,
            credentials: dto.credentials,
            onlineMode: dto.onlineMode,
            updatedAt: dto.updatedAt,
            createdAt: dto.createdAt,
        })
    }

    receiveCredentials(data: { access_token: string, refresh_token: string }): Account {
        const payload = this.decodeAccessTokenPayload(data.access_token)
        console.debug('Received Credentials in account (payload)', payload)
        const newCredentials: CredentialsPrimitives = {
            accessToken: data.access_token, expireAt: payload.exp, refreshToken: data.refresh_token, tokeType: "Bearer"

        }
        return new Account({
            createdAt: this.createdAt,
            credentials: newCredentials,
            guid: new Uuid(payload.sub),
            name: payload.username || payload.givenName || payload.email.split('@')[0] || this.name,
            onlineMode: true,
            updatedAt: this.updatedAt
        })
    }

    private decodeAccessTokenPayload(accessToken: string): AccessTokenClaim {
        const base64Url = accessToken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const rawPayload = JSON.parse(jsonPayload)
        return {email: rawPayload.email, exp: new Date(rawPayload.exp * 1000), givenName: rawPayload.givenName, sub: rawPayload.sub, username: rawPayload.username};
    }
}
