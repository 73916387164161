import {useContext, useEffect, useRef} from "react";
import {
    SignInThirdResponse,
    useAccountApplication
} from "../../../../../../Account/Application/useAccountApplication.tsx";
import {useQueryClient} from "@tanstack/react-query";
import {AppContext} from "../../../../../../../AppContext.tsx";

const loadScript = (src) =>
    new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) return resolve(undefined)
        const script = document.createElement('script')
        script.src = src
        script.onload = () => resolve(undefined)
        script.onerror = (err) => reject(err)
        document.body.appendChild(script)
    })
export const GoogleAuth = () => {

    const googleButton = useRef<HTMLDivElement>(null);
    const {api} = useContext(AppContext)
    const {receiveCredentials} = useAccountApplication()
    const queryClient = useQueryClient()

    useEffect(() => {
        const src = 'https://accounts.google.com/gsi/client'
        const id = process.env.REACT_APP_GOOGLE_GSI_CLIENT_ID

        loadScript(src)
            .then(() => {
                google.accounts.id.initialize({
                    client_id: id,
                    callback: handleCredentialResponse,
                })
                google.accounts.id.renderButton(
                    googleButton.current!,
                    {type: 'standard', theme: 'outline', size: 'large', text:'continue_with', shape: 'pill'}
                )
            })
            .catch(console.error)

        return () => {
            const scriptTag = document.querySelector(`script[src="${src}"]`)
            if (scriptTag) document.body.removeChild(scriptTag)
        }
    }, [])

    async function handleCredentialResponse(response) {
        console.debug('Google Response: ', response)
        console.debug("Encoded JWT ID token: " + response.credential);
        const res = await  api.post<SignInThirdResponse>({route: 'auth/sign-in/third', authenticated: false, body: {
                token: response.credential,
                provider: 'google'
            } })
        console.debug('response from server with crendentials',res)
        await receiveCredentials(res.data)
        await queryClient.resetQueries()
    }

    return (
        <div ref={googleButton}></div>
    )
}
