import {Column, Entity, PrimaryColumn} from "typeorm";
import {dateTransform} from "../../../../Shared/Infrastructure/Persistence/TypeOrm/DateTransform.ts";
import {booleanTransform} from "../../../../Shared/Infrastructure/Persistence/TypeOrm/BooleanTransform.ts";
import {jsonTransform} from "../../../../Shared/Infrastructure/Persistence/TypeOrm/JsonTransform.ts";
import {CredentialsPrimitives} from "../../../Domain/CredentialsPrimitives.ts";

@Entity('account')
export class AccountEntity {
    @PrimaryColumn('varchar')
    guid: string
    @Column({name: 'name', type: 'varchar', nullable: true})
    name: string | null
    @Column({name: 'credentials', type: 'text', nullable: true, transformer: jsonTransform<CredentialsPrimitives>()})
    credentials: CredentialsPrimitives | undefined
    @Column({name: 'online_mode', type: 'varchar', transformer: booleanTransform})
    onlineMode: boolean
    @Column({name: 'updated_at', type: 'integer', transformer: dateTransform})
    updatedAt: Date
    @Column({name: 'created_at', type: 'integer', transformer: dateTransform})
    createdAt: Date
}
