import { PropsWithoutRef, ReactElement } from 'react'
import { Icon } from '../../../../../../Theme/Components/Icon/Icon.tsx'
import { CheckListTemplateItem } from '../../../Domain/CheckListTemplateItem.tsx'

export default function ItemCheckListItem({
  item,
  onMove,
  onRemove,
}: PropsWithoutRef<{
  item: CheckListTemplateItem
  onMove: (item: CheckListTemplateItem, newPosition: number) => void
  onRemove: (item: CheckListTemplateItem) => void
}>): ReactElement {
  const onUpHandler = () => onMove(item, item.sort - 1)
  const onDownHandler = () => onMove(item, item.sort + 1)
  const onRemoveHandler = () => onRemove(item)

  return (
    <div className='flex gap-2'>
      <div className='flex'>
        <div onClick={onUpHandler} style={{ visibility: item.sort === 0 ? 'hidden' : undefined }}>
          <Icon name={'up'} />
        </div>
        <div onClick={onDownHandler}>
          <Icon name={'down'} />
        </div>
      </div>
      <div>
        <strong>{(item.sort || 0) + 1}</strong>
      </div>
      <div className='flex-grow-1'>{item.text}</div>
      <div onClick={onRemoveHandler}>
        <Icon name={'trash'} classname='text-red-500' />
      </div>
    </div>
  )
}
