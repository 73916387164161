import {useContext, useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {CheckList} from "../../Domain/CheckList.tsx";
import {useCheckListQueries} from "../../Infrastructure/use/useCheckListQueries.tsx";
import {AppContext} from "../../../../../AppContext.tsx";
import {capitalizeAllString} from "../../../../Shared/Infrastructure/String/StringCase.ts";
import {InputText} from "primereact/inputtext";
import InputTextInline from "../../../../Shared/Infrastructure/React/Components/Form/TextInline/InputTextInline.tsx";

export const useFormName = ({checklist}: { checklist: CheckList }) => {
    const [visible, setVisible] = useState(false)
    const {trans: {t}, account, api} = useContext(AppContext)
    const [name, setName] = useState<string>(checklist?.name || '')
    const {putCheckList: {mutate}} = useCheckListQueries({account, api})
    useEffect(() => {
        if (!checklist) return
        setName(checklist.name)
    }, [checklist]);

    const openFormName = () => {
        setVisible(true)
        setName(checklist.name)
    }

    const cancelForm = () => {
        setVisible(false)
    }

    const confirmForm = () => {
        const newChecklist = checklist.changeName(name)
        mutate(newChecklist)
        setVisible(false)
    }

    const nameDialog = <Dialog onHide={() => setVisible(false)} visible={visible}
                               header={capitalizeAllString(t('change name'))}>
        <div className="flex justify-content-center">
            <InputText value={name} onChange={(e) => setName(e.target.value)}/>
        </div>
        <div className="flex justify-content-between pt-4">
            <Button severity={'danger'} outlined onClick={cancelForm}>{capitalizeAllString(t('cancel'))}</Button>
            <Button severity={'success'} onClick={confirmForm}>{capitalizeAllString(t('save'))}</Button>
        </div>
    </Dialog>

    const nameInline = <InputTextInline className="text-3xl" value={name} onChange={(name) => {
        const newChecklist = checklist.changeName(name)
        mutate(newChecklist)
    }}/>

    return {nameDialog, openFormName, nameInline}
}
